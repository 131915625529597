<template>
    <LayoutIntranet :isLoading="isLoading">
        <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>
        <div class="row justify-content-center">
            <div class="card p-5 col-md-8">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-6 text-left">
                            <p>Fecha: {{practice.fecha}}</p>
                        </div>
                        <div class="col-md-6">
                            <p class="text-right">Hora: {{practice.hour}}</p>
                        </div>
                    </div>
                    <h3>Resultado de practica</h3>
                    <p>{{practice.approved==0?'Desaprobado':'Aprobado'}}</p>
                    <p>{{practice.q_answer}} ptos</p>
                    <div class="mb-3">
                        <div class="progress">
                            <div class="progress-bar bg-warning" role="progressbar" :style="{width: practice.porcentage+'%'}" :aria-valuenow="practice.porcentage" aria-valuemin="0" aria-valuemax="100">{{practice.porcentage}}%</div>
                        </div>
                    </div>

                </div>
                <div class="card-body">
                    <div class="text-left">
                        <p>
                            <strong>Duración:</strong>
                            <span>{{practice.duration}}</span>
                        </p>
                        <p>
                            <strong>Total de preguntas:</strong>
                            <span>{{practice.q_questions}}</span>
                        </p>
                        <p>
                            <strong>Total de preguntas respondidas:</strong>
                            <span>{{practice.q_answered}}</span>
                        </p>
                        <p>
                            <strong>Total de preguntas correctas:</strong>
                            <span>{{practice.q_answer}}</span>
                        </p>
                        <p>
                            <strong>Total de preguntas incorrectas:</strong>
                            <span>{{practice.q_fail}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center" v-for="detail in practice_details" :key="detail">
            <resultThemeQuestions :detail="detail"></resultThemeQuestions>
        </div>

        <div class="row justify-content-center">
            <div class="col-md-8 mt-4">
                <router-link class="btn btn-warning" :to="{ name: 'PracticeHistory'}">
                    VER HISTORIAL
                </router-link>
            </div>
        </div>
    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import breadcrumbComponent from "@/components/Breadcrumb"
import resultThemeQuestions from "@/components/ResultThemeQuestions"
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import practices from "@/store/practices";
import practice_details from "@/store/practice_details";

export default {
    name: 'practiceResult',
    components: {
        LayoutIntranet,
        breadcrumbComponent,
        resultThemeQuestions
    },
    data() {
        return {
            isLoading:false,
            breadcrumb: {
                title: 'Generador de práctica',
                navbar:[
                    {
                        title: 'Práctica',
                        link:''
                    },
                    {
                        title: 'Practica Resultado',
                        link:''
                    }
                ]
            },
            userData:null,
            group_id:null,
            headers:{},
            practice_id: "",
            practice:[],
            practice_details:[]
        }
    },
    methods:{
        getPractice(){
            this.isLoading = true;
            practices.getPractice(this.practice_id).then(response=>{
                if (response.status=='success'){
                    console.log(response.register)
                    this.practice=response.register
                    let fechaSplit=this.practice.created_at.split(" ")
                    this.practice.fecha=fechaSplit[0]
                    this.practice.hour=fechaSplit[1]
                    console.log(this.practice)
                    this.isLoading = false;
                    this.getpracticeDetails()
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.$router.push({ name: 'PracticeTheme'});
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
            this.isLoading = false;
        },
        getpracticeDetails(){
            this.isLoading = true;
            practice_details.getPracticeDetails(this.practice_id).then(response=>{
                if (response.status=='success'){
                    this.practice_details=response.registers
                    this.isLoading = false;
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.$router.push({ name: 'PracticeTheme'});
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        }
    },
    mounted() {
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.userData=JSON.parse(localStorage.user)
            this.group_id=this.userData.group_id
            this.headers={
                token: localStorage.token,
                application: 'web'
            }
            this.practice_id=this.$route.params.id
            this.getPractice()
        }
    }
}
</script>
<style>
    .text-right{
        text-align: right;
    }
    .resultOptions_option{
        color: #299736;
        margin: 10px 0;
        font-size: 18px;
        display: flex;
    }
    .resultOptions_option.error{
        color: #ED1C24;
    }
    .cuadro{
        width: 29px;
        height: 25px;
        border: 2px solid #299736;
        margin: 0 10px;
        position: relative;
    }
    .error .cuadro{
        border: 2px solid #ED1C24;
    }

    .check .cuadro:after, .check .cuadro:before {
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        width: 20px;
        border: 1px solid #000;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .check .cuadro:before {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
</style>
