<template>
    <LayoutIntranet>
        <div class="row">
            <h1 class="mb-3">Simulador Águila Seis</h1>
        </div>

        <div class="alerts">
            <Countdown/>
            <BoxAscentReport/>
            <PracticeGlobalUrl/>
        </div>


        <div class="row">
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <BoxLink :title="'Practica un tema'" :icon="'lni-clipboard'" :path="'PracticeTheme'"/>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" v-if="permission.practice">
                <BoxLink :title="'Genera tu primera práctica'" :icon="'lni-clipboard'" :path="'Practice'"/>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" v-if="permission.simulation && userData.suscription=='A'">
                <BoxLink :title="'Genera tu primer simulacro ilimitado'" :icon="'lni-clipboard'" :path="'Simulation'"/>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" v-if="permission.temary && userData.suscription=='A'">
                <BoxLink :title="'Documentos'" :icon="'lni-files'" :path="'Documents'"/>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" v-if="permission.audios && userData.suscription=='A'">
                <BoxLink :title="'Audios'" :icon="'lni-music'" :path="'Audios'"/>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3" v-if="permission.videos && userData.suscription=='A'">
                <BoxLink :title="'Videos'" :icon="'bx bx-play-circle'" :path="'Videos'"/>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <BoxLink :title="'Estadisticas'" :icon="'bx bx-chart'" :path="'Statistics'"/>
            </div>
            <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
                <BoxLink :title="'Perfil'" :icon="'bx bx-user'" :path="'Profile'"/>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <BoxMerits/>
            </div>
            <div class="col-md-6">
                <div class="boxAguila">
                    <a href="https://bit.ly/3KqvFGX" target="_blank">
                        <img src="@/assets/images/superior_aguila.png" style="width: 100%">
                        <span class="boxAguilaText" v-if="userData.suscription=='A'">¿Tienes alguna duda <br>sobre el ascenso?</span>
                        <span class="boxAguilaText2" v-else>Estás en una versión gratuita.<br> Adquiere el simulador aquí</span>
                    </a>
                </div>
            </div>
        </div>


    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import BoxMerits from "@/components/BoxMerits.vue";
import BoxLink from "@/components/boxLink.vue";
import PracticeGlobalUrl from "@/components/PracticeGlobalUrl.vue";
import permissionHelper from "@/helpers/validatePermission.js";
import Countdown from "@/components/Countdown.vue";
import BoxAscentReport from "@/components/BoxAscentReport.vue";


export default {
    name: 'DashboardView',
    components: {
        LayoutIntranet,
        BoxMerits,
        BoxLink,
        Countdown,
        PracticeGlobalUrl,
        BoxAscentReport
    },

    data() {
        return {
            userData:{},
            group_id:null,
            permission:[],
            merits:[],
            countdown_exam:'',
            countdownView:false,
            ascent:false
        }
    },
    methods:{
    },
    created() {

    },
    mounted() {
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.userData=JSON.parse(localStorage.user)
            this.group_id=this.userData.group_id
            this.headers={
                token: localStorage.token,
                application: 'web'
            }
            this.permission.practice=permissionHelper.valiPermission('generador-practica',this.userData.permissions)
            this.permission.simulation=permissionHelper.valiPermission('autoevaluacion',this.userData.permissions)
            this.permission.temary=permissionHelper.valiPermission('temario',this.userData.permissions)
            this.permission.audios=permissionHelper.valiPermission('audios',this.userData.permissions)
            this.permission.videos=permissionHelper.valiPermission('videos',this.userData.permissions)
            this.permission.practiceGlobal=permissionHelper.valiPermission('practica-global',this.userData.permissions)
            this.permission.zonaStudy=permissionHelper.valiPermission('zona-de-estudio',this.userData.permissions)
        }
    }
}
</script>
<style>
    .text-alertDashboard{
        font-size: 18px;
    }
    .boxAguila{
        position: relative;
        width: 300px;
        float: right;
    }
    .boxAguilaText{
        position: absolute;
        background-color: #25D366;
        color: #fff;
        padding: 3px 20px;
        left: -130px;
        top: 20px;
        border-radius: 20px;
        font-size: 18px;
    }
    .boxAguilaText2{
        position: absolute;
        background-color: #ff2b2b;
        color: #fff;
        padding: 3px 20px;
        left: -200px;
        top: 20px;
        border-radius: 20px;
        font-size: 18px;
    }
    @media(max-width: 767px){
        .text-alertDashboard{
            font-size: 15px;
        }
    }
</style>
