<template>
    <li class="nav-item dropdown dropdown-large">
        <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span class="alert-count">7</span>
            <i class='bx bx-bell'></i>
        </a>
        <div class="dropdown-menu dropdown-menu-end">
            <a href="javascript:;">
                <div class="msg-header">
                    <p class="msg-header-title">Notifications</p>
                    <p class="msg-header-clear ms-auto">Marks all as read</p>
                </div>
            </a>
            <div class="header-notifications-list">
                <a class="dropdown-item" href="javascript:;">
                    <div class="d-flex align-items-center">
                        <div class="notify bg-light-primary text-primary"><i class="bx bx-group"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h6 class="msg-name">New Customers<span class="msg-time float-end">14 Sec
												ago</span></h6>
                            <p class="msg-info">5 new user registered</p>
                        </div>
                    </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                    <div class="d-flex align-items-center">
                        <div class="notify bg-light-danger text-danger"><i class="bx bx-cart-alt"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h6 class="msg-name">New Orders <span class="msg-time float-end">2 min
												ago</span></h6>
                            <p class="msg-info">You have recived new orders</p>
                        </div>
                    </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                    <div class="d-flex align-items-center">
                        <div class="notify bg-light-success text-success"><i class="bx bx-file"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h6 class="msg-name">24 PDF File<span class="msg-time float-end">19 min
												ago</span></h6>
                            <p class="msg-info">The pdf files generated</p>
                        </div>
                    </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                    <div class="d-flex align-items-center">
                        <div class="notify bg-light-warning text-warning"><i class="bx bx-send"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h6 class="msg-name">Time Response <span class="msg-time float-end">28 min
												ago</span></h6>
                            <p class="msg-info">5.1 min avarage time response</p>
                        </div>
                    </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                    <div class="d-flex align-items-center">
                        <div class="notify bg-light-info text-info"><i class="bx bx-home-circle"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h6 class="msg-name">New Product Approved <span
                                class="msg-time float-end">2 hrs ago</span></h6>
                            <p class="msg-info">Your new product has approved</p>
                        </div>
                    </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                    <div class="d-flex align-items-center">
                        <div class="notify bg-light-danger text-danger"><i class="bx bx-message-detail"></i>
                        </div>
                        <div class="flex-grow-1">
                            <h6 class="msg-name">New Comments <span class="msg-time float-end">4 hrs
												ago</span></h6>
                            <p class="msg-info">New customer comments recived</p>
                        </div>
                    </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                    <div class="d-flex align-items-center">
                        <div class="notify bg-light-success text-success"><i class='bx bx-check-square'></i>
                        </div>
                        <div class="flex-grow-1">
                            <h6 class="msg-name">Your item is shipped <span class="msg-time float-end">5 hrs
												ago</span></h6>
                            <p class="msg-info">Successfully shipped your item</p>
                        </div>
                    </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                    <div class="d-flex align-items-center">
                        <div class="notify bg-light-primary text-primary"><i class='bx bx-user-pin'></i>
                        </div>
                        <div class="flex-grow-1">
                            <h6 class="msg-name">New 24 authors<span class="msg-time float-end">1 day
												ago</span></h6>
                            <p class="msg-info">24 new authors joined last week</p>
                        </div>
                    </div>
                </a>
                <a class="dropdown-item" href="javascript:;">
                    <div class="d-flex align-items-center">
                        <div class="notify bg-light-warning text-warning"><i class='bx bx-door-open'></i>
                        </div>
                        <div class="flex-grow-1">
                            <h6 class="msg-name">Defense Alerts <span class="msg-time float-end">2 weeks
												ago</span></h6>
                            <p class="msg-info">45% less alerts last 4 weeks</p>
                        </div>
                    </div>
                </a>
            </div>
            <a href="javascript:;">
                <div class="text-center msg-footer">View All Notifications</div>
            </a>
        </div>
    </li>
</template>
<script>
export default {
    name: "NotificationsComponent",
    components: {
    },
    props:{
        isLoading:{
            type:Boolean,
            default:true
        }
    },
    mounted() {
    }
};
</script>

<style>

</style>
