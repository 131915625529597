<template>
    <Loading :isLoading="isLoading"></Loading>
    <div class="wrapper" :class="{'menuVertical':menuVertical}">


        <!--sidebar wrapper -->
        <div v-if="!menuVertical" id="sidebar-wrapper" class="sidebar-wrapper" data-simplebar="true">
            <div class="sidebar-header">
                <div>
                    <router-link :to="{ name: 'Dashboard'}">
                        <img src="@/assets/images/logo.png" class="logo_header" alt="logo icon">
                    </router-link>
                </div>
                <div class="toggle-icon ms-auto"><i class='bx bx-arrow-to-left'></i>
                </div>
            </div>
            <!--navigation-->

            <NavigationList/>

            <!--end navigation-->
        </div>
        <!--end sidebar wrapper -->
        <!--start header -->
        <header>
            <div class="topbar d-flex align-items-center">
                <nav class="navbar navbar-expand">

                    <div v-if="menuVertical" class="topbar-logo-header">
                        <div class="">
                            <router-link :to="{ name: 'Dashboard'}">
                                <img src="@/assets/images/logo.png" class="logo_header" alt="logo icon">
                            </router-link>
                        </div>
                    </div>

                    <div class="mobile-toggle-menu"><i class='bx bx-menu'></i>
                    </div>

                    <div v-if="menuVertical" class="topbar-logo-header">
                        <router-link :to="{ name: 'Simulation'}" class="text-white">
                            <i class="bx bx-right-arrow-alt"></i>
                            regresar
                        </router-link>
                    </div>
                    <div class="top-menu ms-auto">
                        <ul class="navbar-nav align-items-center">
                            <Notifications/>
                            <Messages/>
                        </ul>
                    </div>
                    <div class="user-box dropdown">
                        <a class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="@/assets/images/icon-male.png" class="user-img" alt="user avatar">
                            <div class="user-info ps-3">
                                <p class="user-name mb-0">{{this.userData.nickname}}</p>
                                <p class="designattion mb-0">{{this.userData.grade_name}}</p>
                            </div>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li><router-link class="dropdown-item" :to="{ name: 'Profile'}"><i class="bx bx-user"></i><span>Perfil</span></router-link>
                            </li>
                            <li><router-link class="dropdown-item" :to="{name:'PasswordRestart'}"><i class="bx bx-cog"></i><span>Cambiar contraseña</span></router-link>
                            </li>
                            <li>
                                <router-link :to="{name:'Statistics'}" class="dropdown-item">
                                    <i class='bx bx-home-circle'></i><span>Estadisticas</span>
                                </router-link>
                            </li>
                            <li>
                                <div class="dropdown-divider mb-0"></div>
                            </li>
                            <li>
                                <router-link class="dropdown-item" :to="{name:'Logout'}"><i class="bx bx-log-out-circle"></i><span>Logout</span></router-link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
        <!--end header -->


        <!--start page wrapper -->
        <div class="page-wrapper">
            <div class="page-content">
                <slot />
            </div>
        </div>
        <!--end page wrapper -->
        <!--start overlay-->
        <div class="overlay toggle-icon"></div>
        <!--end overlay-->
        <!--Start Back To Top Button-->
        <a href="javaScript:;" class="back-to-top"><i class='bx bxs-up-arrow-alt'></i></a>
        <!--End Back To Top Button-->
        <footer class="page-footer">
            <p class="mb-0">Copyright © 2021. All right reserved.</p>
        </footer>

    </div>
</template>

<script>
import "@/assets/plugins/simplebar/css/simplebar.css"

import "@/assets/css/bootstrap.min.css"
import "@/assets/css/bootstrap-extended.css"
import "@/assets/css/apps.css"
import "@/assets/css/icons.css"

import "@/assets/css/dark-theme.css"
import "@/assets/css/semi-dark.css"
import "@/assets/css/header-colors.css"

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import 'bootstrap'
import SimpleBar from 'simplebar';
import NavigationList from "@/components/Navigation.vue";
import Notifications from "@/components/Notifications.vue";
import Messages from "@/components/Messages.vue";
import Loading from '@/components/Loading.vue'
import $ from 'jquery';

export default {
    name: 'layoutWeb',
    data() {
        return {
            userData:[],
            headers:{},
        }
    },
    components:{
        NavigationList,
        Notifications,
        Messages,
        Loading
    },
    props:{
      isLoading:{
          type:Boolean,
          default:false
      },
        menuVertical:{
          type:Boolean,
            default: false
        }
    },
    methods: {

    },
    mounted() {
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.userData=JSON.parse(localStorage.user)
            this.group_id=this.userData.group_id
            this.headers={
                token: localStorage.token,
                application: 'web'
            }

            if (this.menuVertical==false){
                new SimpleBar(document.getElementById('sidebar-wrapper'), { autoHide: false,height: '100%' });
            }

            $(".mobile-toggle-menu").on("click", function() {
                $(".wrapper").addClass("toggled")
            })
            $(".toggle-icon").click(function() {
                $(".wrapper").hasClass("toggled") ? ($(".wrapper").removeClass("toggled"), $(".sidebar-wrapper").unbind("hover")) : ($(".wrapper").addClass("toggled"), $(".sidebar-wrapper").hover(function() {
                    $(".wrapper").addClass("sidebar-hovered")
                }, function() {
                    $(".wrapper").removeClass("sidebar-hovered")
                }))
            })
            $("html").attr("class", "semi-dark")
            $("html").addClass("color-header headercolor2")
        }
    },
}
</script>

<style>
    .logo_header{
        width: 80%;
    }
    html.semi-dark .sidebar-wrapper .metismenu a {
      color: #FECC00;
    }
    .menuVertical .topbar{
        left: 0;
        background: #212529;
    }
    .menuVertical .topbar .navbar-nav .nav-link {
        color: #fff;
    }
    .menuVertical .page-wrapper{
        margin-left: 0;
    }
</style>
