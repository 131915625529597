<template>
    <LayoutIntranet :isLoading="isLoading">
        <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>
    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import breadcrumbComponent from "@/components/Breadcrumb"
import simulations from "@/store/simulations";
export default {
    name: 'StatisticsView',
    components: {
        LayoutIntranet,
        breadcrumbComponent,
    },
    data() {
        return {
            breadcrumb: {
                title: 'Simulacro',
                navbar: [
                    {
                        title: 'Simulacro',
                        link: ''
                    },
                    {
                        title: 'Iniciar',
                        link: ''
                    }
                ]
            },
            isLoading: true,
            userData:{},
        }
    },
    methods:{
        getSimulations(){
            simulations.getSimulations(1).then(response=>{
                this.isLoading=true;
                if (response.status=='success'){
                    console.log(response)
                    this.isLoading=false;
                }else if(response.status=='error'){
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        }
    },
    mounted() {
        this.userData=JSON.parse(localStorage.user)
        this.headers={
            token: localStorage.token,
            application: 'web'
        }
        this.getSimulations()
    }
}
</script>
<style>
</style>
