<template>
    <LayoutIntranet>
        <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>
        <div class="row">
            <div class="col-lg-4">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex flex-column align-items-center text-center">
                            <img src="@/assets/images/icon-male.png" alt="Admin" class="rounded-circle p-1 bg-primary" width="110">
                            <div class="mt-3">
                                <h4>{{customerData.names}} {{customerData.lastname}}</h4>
                                <p class="text-secondary mb-1">{{customerData.grade_name}}</p>
                            </div>
                        </div>
                        <hr class="my-4">
                        <h5>Suscripción</h5>
                        <ul class="list-group list-group-flush">
                            <li v-if="customerData.suscription=='A'" class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                <h6 class="mb-0">Periodo</h6>
                                <span class="text-secondary">Del {{customerData.period}} al {{customerData.period_finish}}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                <h6 class="mb-0">Suscripción</h6>
                                <span class="text-secondary">
                                    <span v-if="customerData.suscription=='A'">Activo</span>
                                    <span v-else>Inactivo</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="card">
                    <div class="card-body">
                        <h5 class="mt-2">Cambiar contraseña</h5>
                        <hr class="mb-5">
                        <form v-on:submit.prevent="updatePassword">
                            <div class="row m-3">
                                <div v-if="alert.success" class="alert alert-success border-0 bg-success alert-dismissible fade show">
									<div class="text-white">{{alert.message}}</div>
									<!-- <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> -->
								</div>
                                <div v-if="alert.error" class="alert alert-danger border-0 bg-danger alert-dismissible fade show">
									<div class="text-white">{{alert.message}}</div>
									<!-- <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> -->
								</div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">Contraseña anterior</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="password" class="form-control" v-model="form.password" placeholder="contraseña anterior">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">Nueva contraseña</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="password" class="form-control" v-model="form.new_password" placeholder="nueva contraseña">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-3">
                                    <h6 class="mb-0">Repetir contraseña</h6>
                                </div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="password" class="form-control" v-model="form.re_password" placeholder="repetir contraseña nueva">
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col-sm-9 text-secondary">
                                    <input type="submit" class="btn btn-primary px-4" value="Actualizar">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    <notifications position="bottom right" classes="my-custom-class" />
    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import breadcrumbComponent from "@/components/Breadcrumb"
import axios from "axios";

export default {
    name: 'PerfilView',
    data() {
        return {
            breadcrumb: {
                title: 'Recuperar contraseña',
                navbar:[
                    {
                        title:'Perfil',
                        link:'Profile'
                    },
                    {
                        title:'Recuperar contraseña',
                        link:''
                    }
                ]
            },
            customerData:[],
            userData:null,
            headers:{},
            user:[],
            form:{
                password:'',
                re_password:'',
                new_password:''
            },
            alert:{
                success:false,
                error:false,
                message:''
            }
        }
    },
    components: {
        breadcrumbComponent,
        LayoutIntranet
    },
    methods:{
        updatePassword(){
            this.isLoading = true;
            let config=this.headers
            console.log(this.form)
            axios.post(process.env.VUE_APP_API_URL+'customers/password/'+this.userData.id,this.form, config)
              .then(data=>{
                if (data.data.status=='success'){
                    console.log(data)
                    this.alert.success=true
                    this.alert.message='Contraseñas actualizadas correctamente';
                    this.alert.error=false
                }else{
                    this.alert.error=true
                    this.alert.message=data.data.message;
                    this.alert.success=false
                }
              })
        },
        customer(){
            this.isLoading = true;
            let config=this.headers
            axios.get(process.env.VUE_APP_API_URL+'customers/'+this.userData.id, config)
                .then(data=>{
                    if (data.data.status=='success'){
                        this.customerData=data.data.register
                        let period=this.customerData.period.split('-')
                        this.customerData.period= period[2]+'/'+period[1]+'/'+period[0]
                        let period_finish=this.customerData.period_finish.split('-')
                        this.customerData.period_finish= period_finish[2]+'/'+period_finish[1]+'/'+period_finish[0]

                        axios.get(process.env.VUE_APP_API_URL+'groups/'+this.customerData.group_id, config)
                            .then(data=>{
                                if (data.data.status=='success'){
                                    this.customerData.group_name=data.data.register.name
                                }else{
                                    this.error=true;
                                    this.error_msg=data.data.message;
                                }
                            })
                        axios.get(process.env.VUE_APP_API_URL+'grades/'+this.customerData.grade_id, config)
                            .then(data=>{
                                if (data.data.status=='success'){
                                    this.customerData.grade_name=data.data.register.name
                                }else{
                                    this.error=true;
                                    this.error_msg=data.data.message;
                                }
                            })
                    }else{
                        this.error=true;
                        this.error_msg=data.data.message;
                    }
                })
            this.isLoading = false;
        }
    },
    mounted() {
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.userData=JSON.parse(localStorage.user)
            this.headers={
                token: localStorage.token,
                application: 'web'
            }
            this.customer()
        }
    }
}
</script>
