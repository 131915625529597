import { createRouter, createWebHistory } from 'vue-router'
//import LayoutWeb from "@/components/layout/Web";
import LoginView from "@/views/auth/LoginView";
import LogoutView from "@/views/auth/LogoutView";

import RegisterView from "@/views/auth/Register";

import DashboardView from "@/views/DashboardView";
import PracticeThemeView from "@/views/practice-theme/index.vue";
import PracticeThemeInit from "@/views/practice-theme/initial.vue";
import PracticeIndex from "@/views/practice/practiceIndex";
import PracticeInit from "@/views/practice/practiceInit";
import PracticeResult from "@/views/practice/practiceResult";
import PracticeHistory from "@/views/practice/history";

import SimulationInit from "@/views/simulation/simulationIndex";
import SimulationInitial from "@/views/simulation/initial.vue";
import SimulationResult from "@/views/simulation/result.vue";
import SimulationHistory from "@/views/simulation/history.vue";

import PracticeGlobalInitial from "@/views/practice-global/initial.vue"
import PracticeGlobalResult from "@/views/practice-global/result.vue"
import PracticeGlobalScore from "@/views/practice-global/Score.vue";

import Audios from "@/views/temary/audios";
import Documents from "@/views/temary/documents";
import Videos from "@/views/temary/videos";

import Notices from "@/views/notices/Notices";
import NoticeShow from "@/views/notices/NoticeShow";

import PerfilView from "@/views/profile/Perfil";
import PasswordView from "@/views/profile/Password";
import Statistics from "@/views/profile/Statistics";

import ZoneAudio from "@/views/sonido.vue";

import ZoneStudy from "@/views/zone-study/index.vue";
import AscentReport from "@/views/report-ascent/Index.vue";
import AscentReportRiper from "@/views/report-ascent/Riper.vue";

const routes = [

    { path: '/', name: 'Login', component:LoginView },
    { path: '/logout', name: 'Logout', component:LogoutView },
    { path: '/registrar', name: 'Register', component:RegisterView },

    { path: '/prueba-sonido', name: 'zoneAudio', component:ZoneAudio },

    {
        path: '/intranet',
        name: 'Intranet',
        //component:DashboardView,
        children:[
            { path: 'dashboard', name: 'Dashboard', component:DashboardView },

            { path: 'practica-por-tema', name: 'PracticeTheme', component: PracticeThemeView },
            { path: 'practica-por-tema/iniciar', name: 'PracticeThemeInit', component: PracticeThemeInit },
            { path: 'practica-por-tema/finalizar', name: 'PracticeThemeFinish', component: PracticeThemeView },

            { path: 'practica', name: 'Practice', component: PracticeIndex },
            { path: 'practica/iniciar', name: 'PracticeInit', component: PracticeInit },
            { path: 'practica/resultado/:id', name: 'PracticeResult', component: PracticeResult },
            { path: 'practica/historial', name: 'PracticeHistory', component: PracticeHistory },

            { path: 'simulacro', name: 'Simulation', component: SimulationInit },
            { path: 'simulacro/iniciar', name: 'SimulationInitial', component: SimulationInitial },
            { path: 'simulacro/resultado/:id', name: 'SimulationDetail', component: SimulationResult },
            { path: 'simulacro/historial', name: 'SimulationHistory', component: SimulationHistory },

            { path: 'practica-global/iniciar/:code', name: 'PracticeGlobalInitial', component: PracticeGlobalInitial },
            { path: 'practica-global/result/:id', name: 'PracticeGlobalResult', component: PracticeGlobalResult },
            { path: 'practica-global/score/:code', name: 'PracticeGlobalScore', component: PracticeGlobalScore },

            { path: 'noticias', name: 'Notices', component: Notices },
            { path: 'noticias/:slug', name: 'NoticeShow', component: NoticeShow },

            { path: 'documentos', name: 'Documents', component: Documents },
            { path: 'audios', name: 'Audios', component: Audios },
            { path: 'videos', name: 'Videos', component: Videos },

            { path: 'perfil', name: 'Profile', component: PerfilView },
            { path: 'perfil/estadisticas', name: 'Statistics', component: Statistics },
            { path: 'cambiar-contrasena', name: 'PasswordRestart', component: PasswordView },

            { path: 'zona-de-estudio', name: 'ZoneStudy', component:ZoneStudy },


            { path: 'reporte-ascenso', name: 'AscentReport', component:AscentReport },
            { path: 'reporte-ascenso/riper', name: 'AscentReportRiper', component:AscentReportRiper },

        ]
    },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
