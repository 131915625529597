import axios from 'axios';

export default {
    getIntranetPractices() {
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.get(process.env.VUE_APP_API_URL+'intranet-practices/url', {headers:headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;

    },
    getIntranetPracticeShow(code) {
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.get(process.env.VUE_APP_API_URL+'intranet-practices/code/'+code, {headers:headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    },
    getIntranetPracticesMerit() {
        let userData=JSON.parse(localStorage.user);
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.get(process.env.VUE_APP_API_URL+'intranet-practices/merit/customer/'+userData.id, {headers:headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    },
    getIntranetPracticesScore(score) {
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        return axios.get(process.env.VUE_APP_API_URL+'intranet-practices/score/'+score, {headers:headers});
    }
}
