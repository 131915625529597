<template>
    <div class="card radius-10 bg-black">
        <div class="card-body">
            <router-link :to="{ name: path}">
                <div class="d-flex align-items-center">
                        <div>
                            <h5 class="text-warning my-1 text-left font-16">{{title}}</h5>
                        </div>
                        <div class="text-warning ms-auto font-30">
                            <i class="lni " :class="icon"></i>
                        </div>
                </div>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: "boxMerits",
    props:{
        title: {
            type:String
        },
        icon: {
            type:String
        },
        path: {
            type:String
        }
    },
    components: {
    },
    mounted() {
    }
};
</script>

<style>
nav{
    padding: 5px 30px;
}
</style>
