<template>
    <div class="alert alert-warning border-0 bg-warning alert-dismissible">
        <div v-if="code" class="text-dark font-18" style="font-weight: bold;">
            Tienes un simulacro de examen, has clic
            <router-link :to="{ name: 'PracticeGlobalInitial',params:{code:code}}">
                aquí
            </router-link>
        </div>

        <div v-else class="text-dark text-alertDashboard" style="font-weight: bold;">Compite con los usuarios de Águila Seis. Te enviaremos notificación via whatsapp</div>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
</template>
<script>

import intranet_practice from "@/store/intranet_practice";
import intranet_results from "@/store/intranet_results";
export default {
    name: "PracticeGlobalUrlComponent",
    props:{
    },
    data() {
        return {
            code:'',
            userData:{},
        }
    },
    methods: {
        getPracticeUrl(){
            intranet_practice.getIntranetPractices().then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    console.log(response)
                    let registers=response.registers
                    if (registers.length>0){
                        for (const register of registers){
                            console.log(register)
                            if (register.grade_ids!='' && register.grade_ids!=null){
                                let grade_ids=register.grade_ids
                                let grades=[];
                                if (grade_ids.includes(',')){
                                    grades=grade_ids.split(',')
                                }else{
                                    grades.push(grade_ids)
                                }
                                if (grades.includes(this.userData.grade_id.toString())){
                                    this.code=register.code;
                                }
                            }else{
                                this.code=register.code
                            }
                            if (register.q_use>0 && register.q_use!='' && register.q_use!=null){
                                let q_use=register.q_use
                                console.log(q_use)
                                intranet_results.getIntranetResultUse(register.id).then(responseUse=>{
                                    if (responseUse.status=='success'){
                                        console.log(responseUse)
                                        if (responseUse.q_use>=q_use){
                                            this.code=""
                                        }
                                    }else if(responseUse.status=='error'){
                                        console.log('error')
                                    }else if (responseUse.status=='token'){
                                        alert("token expirado")
                                        this.$router.push({ name: 'Logout'});
                                    }
                                }).catch(error=>{
                                    console.error(error);
                                })
                            }
                        }
                    }

                }else if(response.status=='error'){
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        }
    },
    mounted() {
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.userData=JSON.parse(localStorage.user)
            this.group_id=this.userData.group_id
            this.headers={
                token: localStorage.token,
                application: 'web'
            }
            this.getPracticeUrl();
        }

    }
};
</script>

<style>
nav{
    padding: 5px 30px;
}
</style>
