<template>
    <!--breadcrumb-->
    <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div class="breadcrumb-title pe-3">{{breadcrumb.title}}</div>
        <div class="ps-3">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb mb-0 p-0">
                    <li class="breadcrumb-item">
                        <router-link :to="{ name: 'Dashboard'}">
                            <i class="bx bx-home-alt"></i>
                        </router-link>
                    </li>

                    <li v-for="item in breadcrumb.navbar" :key="item" class="breadcrumb-item active" aria-current="page">
                        <router-link v-if="item.link!=''" :to="{ name: item.link}">
                            {{item.title}}
                        </router-link>
                        <span v-if="item.link==''">
                            {{item.title}}
                        </span>
                    </li>

                </ol>
            </nav>
        </div>
    </div>
    <!--end breadcrumb-->
</template>
<script>
export default {
    name: "breadcrumbComponent",
    props:{
        breadcrumb:{
            title:{
                type:String,
                default:'titulo'
            },
            navbar: {
                type:Array
            }
        }
    },
    components: {
    },
    mounted() {
    }
};
</script>

<style>
nav{
    padding: 5px 30px;
}
</style>
