<template>
    <div class="card py-2 col-md-8">
        <div class="card-header">
            <h3 class="result_theme_title">{{detail.theme_title}}</h3>
            <div class="progress">
                <div class="progress-bar bg-warning" role="progressbar" :style="{width: detail.porcentage+'%'}" :aria-valuenow="detail.porcentage" aria-valuemin="0" aria-valuemax="100">{{detail.porcentage}}%</div>
            </div>
        </div>
        <div class="card-body text-left"  :set="position=0">
            <div class="question" v-for="question in JSON.parse(detail.questions)" :key="question" :set="position++">
                <div>
                    <p>Pregunta:</p>
                </div>
                <div>
                    <h5 class="result_question_title" v-html="question.value"></h5>
                    <span class="result_question_code">Código de pregunta: 72564</span>
                </div>
                <div>
                    <p></p>
                </div>
                <div class="resultOptions">
                    <div v-if="fails[question.id]!='null'" class="resultOptions_option error check">
                        <i class="far fa-times-circle"></i>
                        <span class="cuadro"> </span>
                        <span class="result_question_alternative">{{fails[question.id]}}</span>
                    </div>
                    <div v-if="answereds[question.id]==answers[question.id]" class="resultOptions_option check">
                        <i class="far fa-check-circle"></i>
                        <span class="cuadro"> </span>
                        <span class="result_question_alternative">{{answers[question.id]}}</span>
                    </div>
                    <div v-else class="resultOptions_option">
                        <i class="far fa-check-circle"></i>
                        <span class="cuadro"> </span>
                        <span class="result_question_alternative">{{answers[question.id]}}</span>
                    </div>
                </div>
                <hr>
            </div>
        </div>
    </div>
    <!--end breadcrumb-->
</template>
<script>
export default {
    name: "resultThemeComponent",
    props:{
        detail:{
            type:Object
        },
    },
    data() {
        return {
            answereds: [],
            answers:[],
            fails:[],
        }
    },
    methods: {
        reordenar(){
            console.log(this.detail)
            let answereds=JSON.parse(this.detail.answereds)
            answereds.forEach((answered) => {
                this.answereds[answered.id]=answered.value
            });
            let answers=JSON.parse(this.detail.answers)
            answers.forEach((answer) => {
                this.answers[answer.id]=answer.value
            });
            let fails=JSON.parse(this.detail.fails)
            fails.forEach((fail) => {
                this.fails[fail.id]=fail.value
            });
        }
    },
    computed: {
        totalCells() {
            return this.columns * this.rows;
        }
    },
    created() {
        // props are exposed on `this`

        this.reordenar()
    }
};
</script>

<style>
    .result_theme_title{
        font-size: 22px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .result_question_title{
        font-size: 20px;
        text-transform: uppercase;
    }
    .result_question_code{
        font-size: 14px;
    }
    .result_question_alternative{
        font-size: 16px;
        text-transform: uppercase;
    }
    @media (max-width: 767px){
        .result_theme_title{
            font-size: 18px;
        }
        .result_question_title{
            font-size: 16px;
            text-align: justify;
        }
        .result_question_code{
            font-size: 12px;
        }
        .result_question_alternative{
            font-size: 14px;
        }
    }
</style>
