import axios from 'axios';

export default {
    getIntranetResult(intranet_result_id){
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.get(process.env.VUE_APP_API_URL+'intranet-results/'+intranet_result_id,{headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    },
    getIntranetResultScore(code) {
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        return axios.get(process.env.VUE_APP_API_URL+'/intranet-results/score/'+code,{headers:headers});
    },
    getIntranetResultUse(intranet_practice_id) {
        let userData=JSON.parse(localStorage.user);
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.get(process.env.VUE_APP_API_URL+'intranet-results/use/'+intranet_practice_id+'/'+userData.id, {headers:headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    },
    storeIntranetResult(json){
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.post(process.env.VUE_APP_API_URL + 'intranet-results/store', json,{headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    },
}
