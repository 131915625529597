<template>
    <div class="bkLoader" v-if="isLoading">
        <div class="bkLoaderContent">
            <div>
                <span class="loader"></span>
            </div>

        </div>

    </div>

</template>
<script>
export default {
    name: "LoadingComponent",
    components: {
    },
    props:{
        isLoading:{
            type:Boolean,
            default:true
        }
    },
    mounted() {
    }
};
</script>

<style>
.bkLoader{
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 75%);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
}
.bkLoaderContent{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #FFF #FFF transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #FF3D00 #FF3D00;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
}
.loader::before {
    width: 32px;
    height: 32px;
    border-color: #FFF #FFF transparent transparent;
    animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes rotationBack {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}
</style>
