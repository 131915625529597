import axios from 'axios';

export default {
    getCollections(theme_id) {
        let userData=JSON.parse(localStorage.user);
        let params={
            theme_id:theme_id,
            customer_id:userData.id
        }
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.get(process.env.VUE_APP_API_URL+'collections',{headers, params}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    },
    storeCollection(json){
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.post(process.env.VUE_APP_API_URL + 'collections/store', json,{headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    },
    updateCollection(group_id, json){
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.put(process.env.VUE_APP_API_URL + "collections/"+group_id, json,{headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    },
    deleteCollection(collection_id) {
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.delete(process.env.VUE_APP_API_URL+'collections/'+collection_id,{headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    }
}
