<template>
    <LayoutIntranet :isLoading="isLoading" :menuVertical="menuVertical">
        <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>
        <div class="simulation" :class="version">
            <div v-if="version=='siecopol'" class="bg-black py-2 row" >
                <h1 class="text-white">POLICÍA NACIONAL</h1>
                <h2 class="text-white">Simulador de examen tipo SIECOPOL</h2>
                <h4 class="text-white">{{title}}</h4>
                <p class="text-white">{{description}}</p>
            </div>
            <div v-if="version=='siecopol'" class="row text-right" style="margin-top: 2px;border-top: 2px solid #1478cf;border-bottom: 2px solid #1478cf;padding: 10px 0;font-size: 16px;font-weight: bold;">
                <span class="">Usuario: Suboficial de Primera carlos palma bocanegra</span>
            </div>
            <div v-if="version=='aguilaseis'" class="row justify-content-center">
                <div class="card col-md-9 px-5 py-5 mb-5" >
                    <div class="row">
                        <div class="col-12 mb-2 bg-black">
                            <h1 class="text-white">{{title}}</h1>
                            <p class="text-white">{{description}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <p class="text-left">
                                Tiempo <span id="countdown">{{countdown.hours}}:{{countdown.minutes}}:{{countdown.seconds}}</span></p>
                        </div>
                        <div class="col-md-6">
                            <span>Preguntas Contestadas: <span class="q__answered">{{practice.totalQuestions-practice.totalUnanswered}}</span></span>
                            <span> | </span>
                            <span>Preguntas sin contestar <span class="q__unanswered">{{practice.totalUnanswered}}</span></span>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <input v-model="searchQuestion" type="text" class="form-control" placeholder="Buscar pregunta N°" aria-label="question search" aria-describedby="button-addon2">
                                <button @click="search" class="btn btn-outline-secondary" type="button" id="button-addon2">Buscar</button>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row questions justify-content-center mt-2" :set="position=0">
                        <div class="col-md-12">
                            <h3 class="text-left px-0 theme_title">{{practice.title}}</h3>
                        </div>
                        <div v-for="(question) in practice.questions" :key="question.id"  class="col-md-12 py-4 question" :class="[{'active':practice.view===position},'question'+question.id+' postion'+position]" :set="position++">
                            <input type="hidden" class="questionAnswerId" :id="'question'+question.id+''" value="">

                            <div>
                                <p class="text-left d-flex question_title">
                                    <span>{{position}}.</span>
                                    <span v-html="question.question"></span>
                                </p>
                            </div>
                            <div class="mb-2">
                                <div class="group_check text-left alternative" v-for="(alternative) in question.alternatives" :key="alternative">
                                    <div class="d-flex align-items-center mb-2 labelAlter" @click="validate($event)" :theme_id="question.theme_id" :questionId="question.id" :answer="alternative.answer" :text="alternative.value">
                                        <span class="checkmark"></span>
                                        <span class="mx-3 text-left question_alternative">{{alternative.value}}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p class="text-left text-uppercase question_code"><strong>Código de pregunta - {{question.code}}</strong></p>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center mb-5">
                        <div class="col-md-12 text-left">
                            <button class="btn btn-primary bg-black" @click="clearAnswer">Borrar respuesta</button>
                        </div>
                    </div>

                    <div class="row navigation justify-content-center mb-3">
                        <div class="d-flex justify-content-center col-md-8">
                            <button @click="prev" :style="[practice.view<=0?{'opacity':'0.5'}:{'opacity':'1'}]" class="btn btn-dark"><i class="lni lni-angle-double-left mx-1"></i> Atrás</button>
                            <button @click="next" :style="[practice.view>=(this.practice.questions.length-1)?{'opacity':'0.5'}:{'opacity':'1'}]" class="btn btn-dark mx-1">Siguiente <i class="lni lni-angle-double-right"></i></button>
                        </div>
                    </div>
                    <div class="row navigation justify-content-center mb-4">
                        <div class="d-flex justify-content-center  col-md-6">
                            <button class="btn btn-warning mx-3 py-2 px-5" @click="openPopupFinalizar">Finalizar Práctica</button>
                        </div>
                    </div>
                </div>

            </div>

            <div v-if="version!='aguilaseis'" style="margin: 0 auto;overflow-x: scroll;width: 100%;" class="mt-4">
                <div class="row justify-content-center" style="width:1400px;margin: 0 auto">
                    <div class="col-4">
                        <div class="table_question p-3 border border-dark" >
                            <p>Tabla de respuestas</p>

                            <div class="table_question_list">
                                <div v-for="col in cols" :key="col">
                                    <ul class="list-unstyled">
                                        <li v-for="question,index in practice.questions" :key="index" @click="searchQuestionIndex(index)" class="text-left list " :class="'list'+question.id">
                                            <a v-if="col*rows >=index+1 && index+1>((col-1)*rows)">
                                                <span>{{index+1}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>



                        </div>
                    </div>
                    <div class="col-8">
                        <div v-if="version=='pnp'">
                            <h1 class="">Simulacro</h1>
                        </div>
                        <div class="row align-items-center justify-content-center">
                            <div class="col-9">
                                <p class="text-left mb-0">
                                    Tiempo <span id="countdown">1:16:57</span> |
                                    <span class="theme_title">{{practice.title}}</span></p>
                            </div>
                            <div class="col-3"><button class="btn py-2 px-2" :class="[(version=='siecopol')?'btn-outline-secondary':'btn-warning']" @click="openPopupFinalizar">Finalizar simulacro</button></div>
                        </div>

                        <div class="row questions justify-content-center" :set="position=0">
                            <div v-for="(question) in practice.questions" :key="question.id"  class="col-md-12 py-4 question" :class="[{'active':practice.view===position},'question'+question.id+' postion'+position]" :set="position++">
                                <input type="hidden" class="questionAnswerId" :id="'question'+question.id+''" value="">

                                <div>
                                    <p class="text-left d-flex question_title">
                                        <span>{{position}}.</span>
                                        <span v-html="question.question"></span>
                                    </p>
                                </div>
                                <div class="mb-2">
                                    <div class="group_check text-left alternative" v-for="(alternative,index) in question.alternatives" :key="index">
                                        <div class="d-flex align-items-center mb-2 labelAlter" @click="validate($event)" :theme_id="question.theme_id" :questionId="question.id" :answer="alternative.answer" :text="alternative.value" :posalter="index">
                                            <span class="checkmark"></span>
                                            <span class="mx-3 text-left question_alternative"  v-if="version=='siecopol'">
                                                {{listLetras[index]}}. {{alternative.value}}
                                            </span>
                                            <span class="mx-3 text-left question_alternative"  v-else>
                                                {{alternative.value}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <p class="text-left text-uppercase question_code"><strong>Código de pregunta - {{question.code}}</strong></p>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center mb-5">
                            <div class="col-md-12 text-left">
                                <button class="btn btn-primary bg-black" @click="clearAnswer">Borrar respuesta</button>
                            </div>
                        </div>
                        <div class="question__result text-left">
                            <span class="question__answered">Preguntas Contestadas: <span class="q__answered">{{practice.totalQuestions-practice.totalUnanswered}}</span></span><span> | </span>
                            <span class="question__unanswered">Preguntas sin contestar <span class="q__unanswered">{{practice.totalUnanswered}}</span></span>
                        </div>
                        <div v-if="version=='siecopol'" class="question__marked">
                            <span v-for="resultaMarked,index in resultaMarkeds" :key="index">{{resultaMarked}}{{resultaMarked!=undefined?',':''}}</span>
                        </div>
                    </div>
                </div>

            </div>

            <div class="popup">
                <div class="popupContent">
                    <div class="popupFinalizar">
                        <div class="popup-header">
                            <h5 class="popup-title">¿Está seguro que quiere finalizar el examén?</h5>
                            <button type="button" class="popup-close btn-close" @click="popupClose"></button>
                        </div>
                        <div class="popupal-body">
                            <button class="btn btn-warning mx-2" @click="finishPractice">Terminar</button>
                            <button type="button" class="btn btn-secondary mx-2" @click="popupClose">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import breadcrumbComponent from "@/components/Breadcrumb"
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import $ from "jquery";
//import group_themes from "@/store/group_themes";
import questions from "@/store/questions";
import intranet_practice from "@/store/intranet_practice";
import intranet_results from "@/store/intranet_results";
import intranet_details from "@/store/intranet_details";
import themes from "@/store/themes";
import uniqid from 'uniqid';

export default {
    name: 'PracticeGlobalInit',
    data() {
        return {
            title:'simulacro',
            description:'',
            code:'',
            intranet_practice_id:'',
            isLoading: true,
            menuVertical: false,
            rows: 20,
            cols: 5,
            initRow: 0,
            listLetras: ['A', 'B', 'C', 'D', 'E'],
            resultaMarkeds: [],
            breadcrumb: {
                title: 'Simulacro Global',
                navbar: [
                    {
                        title: 'Simulacro Global',
                        link: ''
                    },
                    {
                        title: 'Iniciar',
                        link: ''
                    }
                ]
            },
            userData: null,
            headers: {},
            cantidad: 100,
            version: 'aguilaseis',

            practice: {
                title: '',
                totalQuestions: 0,
                totalUnanswered: 0,
                totalAnswer: 0,
                totalFails: 0,
                questions: [],
                view: 0,
                type: null,
                porcentage: 0,
                approved: 0
            },
            searchQuestion: '',
            tiempo: {hora: 0, minuto: 0, segundo: 0},
            duration: "00:00:00",

            theme_ids: [],
            group_theme_id: [],
            themes: [],



            countdown: {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
                targetDate: ""
            },
            timer: null, // variable para el temporizador

        }
    },
    components: {
        LayoutIntranet,
        breadcrumbComponent,
    },
    methods: {
        getPractice(){
            intranet_practice.getIntranetPracticeShow(this.$route.params.code).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    let register=response.register
                    console.log(response)
                    this.title=register.title
                    this.description=register.description
                    this.intranet_practice_id=register.id
                    this.version=register.type_version

                    let theme_ids=JSON.parse(register.theme_ids);
                    let q_question=JSON.parse(register.q_questions);

                    let index=0
                    this.getThemes(theme_ids);
                    for (const theme of theme_ids){
                        this.getQuestionsThemeId(theme,q_question[index])
                        index++
                        this.cantidad=this.cantidad+parseInt(q_question[index])
                    }
                    if (this.version != 'aguilaseis') {
                        this.menuVertical = true;
                    }
                    if (this.cantidad > 299) {
                        this.rows = 60;
                    }
                    //this.getGroupThemes();
                    let nowDate=new Date()
                    nowDate.setMinutes(nowDate.getMinutes() + parseInt(response.register.duration));
                    //nowDate.setMinutes(nowDate.getMinutes() + 2);
                    let now = new Date(nowDate);
                    this.countdown.targetDate=now
                    this.startCountdown()
                    console.log(q_question)

                }else if(response.status=='error'){
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        getThemes(theme_ids){
            themes.postThemes(theme_ids).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    console.log(response)
                    response.registers.forEach((register) => {
                        this.theme_ids.push(register.id)
                        this.group_theme_id[register.id] = register
                        this.themes[register.id] = register.title
                    });
                }else if(response.status=='error'){
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        // getGroupThemes() {
        //     this.isLoading = true;
        //     group_themes.getGroupThemes().then(response=>{
        //         this.loading=true;
        //         if (response.status=='success'){
        //             response.registers.forEach((register) => {
        //                 this.theme_ids.push(register.theme_id)
        //                 this.group_theme_id[register.theme_id] = register
        //                 this.themes[register.theme_id] = register.theme_title
        //             });
        //             console.log(this.group_theme_id)
        //             this.theme_ids.forEach((theme_id) => {
        //                 if (this.cantidad == 100) {
        //                     this.getQuestionsThemeId(theme_id, this.group_theme_id[theme_id].q_question_simulation_one)
        //                 }
        //                 if (this.cantidad == 300) {
        //                     this.getQuestionsThemeId(theme_id, this.group_theme_id[theme_id].q_question_simulation_three)
        //                 }
        //             });
        //         }else if(response.status=='error'){
        //             console.log(response.message)
        //         }else if (response.status=='token'){
        //             alert("token expirado")
        //             this.$router.push({ name: 'Logout'});
        //         }
        //     }).catch(error=>{
        //         console.error(error);
        //     })
        // },
        getQuestionsThemeId(theme_id, quantity = '') {
            this.isLoading = true;
            questions.getQuestionsThemeId(theme_id,quantity).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    response.registers.forEach((element) => {
                        //console.log(element)
                        //element.alternatives = JSON.parse(element.alternatives)
                        element.question = element.question.replaceAll('"', '&quot;')
                        this.practice.questions.push(element)
                    });
                    let questions = response.registers.length
                    this.practice.totalQuestions += questions
                    this.practice.totalUnanswered += questions
                    this.practice.title = this.themes[this.practice.questions[0].theme_id]
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.$router.push({ name: 'PracticeTheme'});
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
            this.isLoading = false;
        },
        search() {
            if (this.searchQuestion > 0 && this.searchQuestion <= this.practice.questions.length) {
                if (this.searchQuestion != '') {
                    this.practice.view = parseInt(this.searchQuestion - 1)
                }
            }
        },
        searchQuestionIndex(view) {
            if (view >= 0 && view <= this.practice.questions.length) {
                this.practice.view = parseInt(view)
                this.practice.title = this.themes[this.practice.questions[view].theme_id]

                if (this.version != 'aguilaseis') {
                    $(".table_question .list").removeClass('view')
                    $(".table_question .list" + this.practice.questions[this.practice.view].id).addClass('view')
                }

            }
        },
        next() {
            let next = this.practice.view + 1
            if (next > (this.practice.totalQuestions - 1)) {
                return false
            }
            this.practice.view = next
            this.practice.title = this.themes[this.practice.questions[next].theme_id]
        },
        prev() {
            let next = this.practice.view - 1
            if (next < 0) {
                return false
            }
            this.practice.view = next
            this.practice.title = this.themes[this.practice.questions[next].theme_id]
        },
        clearAnswer() {
            if ($(".postion" + this.practice.view + " .questionAnswerId").val() != '') {
                $(".postion" + this.practice.view + " .questionAnswerId").val('')
                $(".postion" + this.practice.view + " .alternative").removeClass('success')
                $(".postion" + this.practice.view + " .alternative").removeClass('marked')
                $(".postion" + this.practice.view + " .alternative").removeClass('error')
                this.practice.totalUnanswered++

                if (this.version != 'aguilaseis') {
                    $(".list" + this.practice.questions[this.practice.view].id).removeClass('chec')
                    this.resultaMarkeds.splice(this.practice.view, 1)
                }
            }
        },
        openPopupFinalizar() {
            $(".popup").fadeIn()
        },
        popupClose() {
            $(".popup").fadeOut()
        },
        validate(event) {
            let questionId = event.target.parentElement.getAttribute('questionId')
            let questionAnswer = document.getElementById('question' + questionId).value;
            if (questionAnswer == '') {
                let answerElemente = event.target.parentElement
                let answer = event.target.parentElement.getAttribute('answer')
                answerElemente.parentElement.classList.add("marked");
                if (answer == 1) {
                    answerElemente.parentElement.classList.add("success");
                    this.practice.totalUnanswered--
                    this.practice.totalAnswer++
                    $("#question" + questionId).val(1)
                } else {
                    answerElemente.parentElement.classList.add("error");
                    $(".question" + questionId + " .alternative").each(function () {
                        if ($(this).find(".labelAlter").attr("answer") == 1) {
                            $(this).addClass("success")
                        }
                    });
                    this.practice.totalUnanswered--
                    this.practice.totalFails++
                    $("#question" + questionId).val(0)
                }
                if (this.version != 'aguilaseis') {
                    $(".list" + questionId).addClass('chec')
                    let posalter = event.target.parentElement.getAttribute('posalter')
                    this.resultaMarkeds[this.practice.view] = (this.practice.view + 1) + "" + this.listLetras[posalter]
                }


                this.practice.porcentage = (this.practice.totalAnswer / this.practice.totalQuestions) * 100;
                this.practice.approved = this.practice.porcentage >= this.userData.minimun_score ? 1 : 0;
            }
        },
        finishPractice() {
            this.popupClose()
            this.isLoading = true;
            let json = {
                "code":uniqid(),
                "intranet_practice_id": this.intranet_practice_id,
                "practice_title": this.title,
                "customer_id": this.userData.id,
                "q_question": this.practice.totalQuestions,
                "q_answered": this.practice.totalAnswer + this.practice.totalFails,
                "q_answer": this.practice.totalAnswer,
                "q_fail": this.practice.totalFails,
                "porcentage": Math.ceil(this.practice.porcentage),
                "duration": this.duration,
                "type_version":this.version,
                "grade_id": this.userData.grade_id,
                "dni": this.userData.dni,
                "suscription": this.userData.suscription
            };

            intranet_results.storeIntranetResult(json).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    let intranet_result_id = response.intranetResultId
                    console.log(intranet_result_id)

                    let questions = []
                    let answereds = []
                    let answers = []
                    let fails = []

                    //console.log(this.themes)
                    let themes = this.themes
                    console.log(themes)
                    let routerr = this.$router;
                    themes.forEach(function (index, theme) {
                        questions[theme] = []
                        answereds[theme] = []
                        answers[theme] = []
                        fails[theme] = []
                    })
                    let q_questions = this.practice.questions.length
                    let aument = 0;
                    this.practice.questions.forEach(function (question) {
                        console.log(question)
                        questions[question.theme_id].push({'id': question.id, 'value': question.question});
                        question.alternatives.forEach(function (alternative) {
                            if (alternative.answer == 1) {
                                answers[question.theme_id].push({'id': question.id, 'value': alternative.value});
                            }
                        })
                        let answer = document.getElementById("question" + question.id);
                        if (answer.value == 1) {
                            answereds[question.theme_id].push({
                                'id': question.id,
                                'value': $(".question" + question.id + " .success .labelAlter").attr("text")
                            })
                            fails[question.theme_id].push({'id': question.id, 'value': 'null'})
                        } else if (answer.value === "0") {
                            answereds[question.theme_id].push({'id': question.id, 'value': 'null'})
                            fails[question.theme_id].push({
                                'id': question.id,
                                'value': $(".question" + question.id + " .error .labelAlter").attr("text")
                            })
                        } else {
                            answereds[question.theme_id].push({'id': question.id, 'value': 'null'})
                            fails[question.theme_id].push({'id': question.id, 'value': 'null'})
                        }
                        aument++
                        if (q_questions == aument) {
                            console.log(themes)
                            themes.forEach(function (title, theme_id) {
                                if (questions[theme_id].length > 0) {
                                    let q_answer = 0
                                    answereds[theme_id].forEach(function (answer) {
                                        if (answer.value != "null") {
                                            q_answer++
                                        }
                                    })

                                    let porcentage = (q_answer / questions[theme_id].length) * 100
                                    console.log(porcentage)
                                    let jsonDetail = {
                                        "intranet_result_id": intranet_result_id,
                                        "theme_id": theme_id,
                                        "theme_title": title,
                                        "porcentage": Math.ceil(porcentage),
                                        "questions": JSON.stringify(questions[theme_id]),
                                        "answers": JSON.stringify(answers[theme_id]),
                                        "answereds": JSON.stringify(answereds[theme_id]),
                                        "fails": JSON.stringify(fails[theme_id]),
                                    }
                                    console.log(jsonDetail)
                                    intranet_details.storeIntranetDetails(jsonDetail).then(response=>{
                                        if (response.status=='success'){
                                            console.log(response)
                                        }else if(response.status=='error'){
                                            console.log(response.message)
                                        }else if (response.status=='token'){
                                            alert("token expirado")
                                            this.$router.push({ name: 'Logout'});
                                        }
                                    }).catch(error=>{
                                        console.error(error);
                                    })
                                }
                                routerr.push({name: 'PracticeGlobalResult', params: {id: intranet_result_id}});
                            })
                        }
                    })
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.$router.push({ name: 'PracticeTheme'});
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        startCountdown() {
            const targetTime = new Date(this.countdown.targetDate).getTime()
            this.timer = setInterval(() => {
                const now = new Date().getTime()
                const distance = targetTime - now
                this.countdown.days = Math.floor(distance / (1000 * 60 * 60 * 24))
                this.countdown.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
                this.countdown.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
                this.countdown.seconds = Math.floor((distance % (1000 * 60)) / 1000)
                if (distance <= 0) {
                    clearInterval(this.timer)
                    this.finishPractice()
                    // hacer algo cuando se acabe el tiempo
                }
            }, 1000) // actualiza el contador cada segundo
        }
    },
    created: function () {
        //setInterval(this.showRemaining(), 1000);
    },
    mounted() {
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.urlHost = process.env.VUE_APP_API_URL
            this.userData = JSON.parse(localStorage.user)
            this.headers = {
                token: localStorage.token,
                application: 'web',
                user_id: this.userData.id
            }
            console.log(this.userData)
            this.getPractice()
        }
    },
}
</script>
<style>
    .simulation .question{
        display: none;
    }
    .simulation .question.active{
        display: block;
    }
    .simulation .alternative label{
        display: flex;
        align-items: center;
        text-align: left;
        width: 100%;
        padding-bottom: 5px;
        font-size: 12px;
    }
    .simulation span.checkmark:before {
        width: 25px;
        height: 25px;
        display: block;
        content: "";
        margin-right: 5px;
    }
    .simulation .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 2px;
        height: 9px;
        border: solid #18C0C9;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .simulation .checkmark:after {
        width: 100%;
        height: 100%;
        border: none;
        background-color: #299736;
        top: 0;
        left: 0;
        transform: inherit;
    }
    .simulation .checkmark{
        position: relative;
        display: inline-block;
        background-color: #fff;
        border: 1px solid #707070;
        border-radius: 3px;
        /*margin-right: 10px;*/
        cursor: pointer;
        width: 25px !important;
        height: 25px !important;
    }

    /*.simulation .success .checkmark:after {*/
    /*    display: block;*/
    /*    background-color: #299736;*/
    /*}*/
    /*.simulation .error .checkmark:after {*/
    /*    display: block;*/
    /*    background-color: #ED1C24;*/
    /*}*/

    .simulation .marked .checkmark:after {
        display: block;
        background-color: #fecc00;
    }

    .simulation .alternative span {
        font-size: 15px;
    }

    .popup{
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 90%);
        top: 0;
        left: 0;
        z-index: 99;
    }
    .popupContent{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .popupFinalizar{
        background-color: #fff;
        padding: 40px 20px;
        border-radius: 8px;
        max-width: 600px;
        position: relative;
    }
    .popup-title{
        font-size: 35px;
    }
    .popup-close{
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .table_question_list{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 450px;
        margin: 0 auto;
    }
    .table_question_list>div{
        flex-basis: 100%;
        flex: 1 0 20%;
        margin: 5px 0;
    }
    .table_question_list ul li{
        cursor: pointer;
    }
    .table_question_list ul li a:before {
        content: "";
        width: 10px;
        height: 10px;
        border: 1px solid #000;
        display: inline-block;
        margin-right: 5px;
    }
    .table_question_list ul li.chec a:before {
        background-color: #fecc00;
    }
    .pnp .table_question_list ul li a:before {
        content: "";
        width: 15px;
        height: 15px;
    }

    .table_question_list ul li a{
        font-size: 16px;
    }
    .pnp .question_title{
        font-size: 16px;
        border-top: 1px solid #000;
        padding: 10px 0;
    }
    .siecopol .table_question_list ul li a:before {
        content: "";
        width: 15px;
        height: 15px;
        border-radius: 50%;
    }
    .siecopol .theme_title{
        font-size: 16px;
        color: #1478cf;
    }
    .siecopol .question_title{
        font-size: 16px;
        border-top: 1px solid #1478cf;
        border-bottom: 1px solid #1478cf;
        padding: 10px 0;
    }
    .siecopol .checkmark{
        border-radius: 50%;
    }
    .siecopol .checkmark{
        width: 15px!important;
        height: 15px!important;
    }
    .siecopol .checkmark:after {
        width: 50%;
        height: 50%;
        border: none;
        top: 3px;
        left: 3px;
        transform: inherit;
        border-radius: 50%;
    }
    .siecopol .marked .checkmark:after {
        display: block;
        background-color: #000;
    }
    .siecopol .table_question_list ul li a{
        position: relative;
    }
    .siecopol .table_question_list ul li.chec a:before{
        background-color: inherit;
    }
    .siecopol .table_question_list ul li.view a:after{
        content: "";
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #4c4c4c;
        position: absolute;
        left: 4px;
        top: 3px;
    }
    .siecopol span.checkmark:before {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }
    .siecopol .chec span{
        background-color: red;
        color: #fff;
    }
    .question__result{
        border-top: 1px solid #000;
        padding-top: 10px;
    }
    .siecopol .question__result{
        border-top-color: #1478cf;
        color: #1478cf;
    }
    .question__marked{
        text-align: left;
        color: #1478cf;
    }
</style>
