import axios from 'axios';

export default {
    getAscentCustomer() {
        let userData=JSON.parse(localStorage.user);
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.get(process.env.VUE_APP_API_URL+'ascents/customer/'+userData.id, {headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    }
}
