<template>
    <LayoutIntranet>
        <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>
        <div class="row">
            <div class="card">
                <div class="card-header">
                    <h5 class="title text-left">Filtros</h5>
                </div>
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-md-4 form-group">
                            <p class="text-left m-0">Elige el Tema:</p>
                            <select class="form-select" name="theme_id" v-model="theme_id">
                                <option value="">Seleccionar tema</option>
                                <option v-for="theme in themes" :key="theme" :value="theme.id" >{{theme.title}}</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <p class="text-left m-0">Buscador general:</p>
                            <input class="form-control" type="text" name="search" v-model="inputSearch">
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-dark px-2 mx-2" @click="searchForm()">
                                <i class="lni lni-search"></i>
                                <span>Buscar</span>
                            </button>
                            <button class="btn btn-danger px-2" @click="clearForm()">
                                <i class="lni lni-eraser"></i>
                                <span>Cancelar</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row audios">
            <div class="audiosButtons mb-2">
                <button v-if="audios.length>0" class="btn rounded-0 m-1" :class="[sortOption==='*'? 'btn-warning' : 'btn-dark']" @click="sort('*')">Ver todo</button>
                <button class="btn rounded-0 m-1" v-for="(btn) in audiosCategories" :key="btn" :class="[sortOption==='categorie'+btn.temary_categorie_id? 'btn-warning' : 'btn-dark']"
                    @click="sort('categorie'+btn.temary_categorie_id)">{{btn.title}}</button>
            </div>
            <div class="row grid">
                <div v-for="(audio) in audios" :key="audio.id" :class="'categorie'+audio.temary_categorie_id+' col-md-6 col-xl-4 col-xxl-3 mb-3 audio'">
                    <div class="card px-4" style="height:100%">
                        <div>
                            <div style="font-size:60px">
                                <i class="lni lni-music text-warning"></i>
                            </div>
                            <audio :src="urlHost+'static/audio/'+audio.archive" controlslist="nodownload" controls="controls" type="audio/aac" preload="preload"></audio>
                        </div>
                        <h5 class="text-left bold"><strong>{{audio.title}}</strong></h5>
                        <p class="text-left">{{audio.description}}</p>
                    </div>
                </div>
            </div>
        </div>

    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery'
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import breadcrumbComponent from "@/components/Breadcrumb"
import group_themes from "@/store/group_themes";
import themes from "@/store/themes";
import audios from "@/store/audios";
import permissionHelper from "@/helpers/validatePermission";


export default {
    name: 'AudiosView',
    data() {
        return {
            breadcrumb: {
                title: 'Audios',
                navbar:[
                    {
                        title:'Audio',
                        link:''
                    }
                ]
            },
            urlHost:'',
            audios:[],
            audiosCategories:[],
            themes:[],
            userData:null,
            headers:{},
            sortOption:'*',
            inputSearch:'',
            theme_id:'',
            group_theme_ids:[]
        }
    },
    components: {
        breadcrumbComponent,
        LayoutIntranet,
    },
    methods:{
        getGroupThemes(){
            this.isLoading = true;
            group_themes.getGroupThemes().then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    response.registers.forEach((register) => {
                        this.group_theme_ids.push(register.theme_id)
                    });
                    this.getThemes(this.group_theme_ids)
                    this.getAudios(this.group_theme_ids)
                }else if(response.status=='error'){
                    this.ascent=false
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        getThemes(theme_ids){
            this.isLoading = true;
            themes.postThemes(theme_ids).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    this.themes=response.registers
                }else if(response.status=='error'){
                    this.ascent=false
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
            this.isLoading = false;
        },
        getAudios(theme_ids){
            this.isLoading = true;
            audios.postAudios(theme_ids,this.inputSearch).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    this.audios=response.registers
                    let audiosCategories=[]
                    response.registers.forEach((register) => {
                        audiosCategories.push({
                            temary_categorie_id:register.temary_categorie_id,
                            title:register.temary_categorie_name
                        })
                    });
                    let personasMap = audiosCategories.map(item=>{
                        return [item.title,item]
                    });
                    var personasMapArr = new Map(personasMap); // Pares de clave y valor
                    let unicos = [...personasMapArr.values()]; // Conversión a un array
                    this.audiosCategories=unicos
                }else if(response.status=='error'){
                    this.ascent=false
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })

            this.isLoading = false;
        },
        sort(key){
            this.sortOption=key
            if(this.sortOption=='*'){
                $(".audio").fadeIn()
            }else{
                $(".audio").fadeOut()
                $("."+this.sortOption).fadeIn()
            }
        },
        searchForm(){
            let theme_ids=[]
            if(this.theme_id!=''){
                theme_ids=[this.theme_id]
            }else{
                theme_ids=this.group_theme_ids
            }

            this.getAudios(theme_ids,this.inputSearch)
        },
        clearForm(){
            this.inputSearch=''
            this.theme_id=''
            this.getAudios(this.group_theme_ids)
        }
    },
    mounted() {
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.urlHost=process.env.VUE_APP_API_URL
            this.userData=JSON.parse(localStorage.user)
            this.headers={
                token: localStorage.token,
                application: 'web',
                user_id: this.userData.id
            }
            if (!permissionHelper.valiPermission('audios',this.userData.permissions) || this.userData.suscription=='I'){
                this.$router.push({ name: 'Dashboard'});
            }
            this.getGroupThemes()
        }
    }
}
</script>
