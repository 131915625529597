<template>
    <div class="alert alert-success border-success bg-success alert-dismissible fade show" v-if="ascent">
        <div class="text-black text-alertDashboard" style="font-weight: bold;">Su reporte de probabilidad de ascenso ya esta listo, has clic
            <router-link :to="{ name: 'AscentReport'}" style="text-decoration: underline">
                aqui
            </router-link>
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
</template>
<script>

import customers from "@/store/customers";
export default {
    name: "BoxAscentReportComponent",
    props:{
    },
    data() {
        return {
            userData:{},
            ascent:false
        }
    },
    methods: {
        getCustomerAscent(){
            //verificamos si el usuario tiene activo su reporte de ascenso
            customers.getAscent().then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    this.ascent=true
                }else if(response.status=='error'){
                    this.ascent=false
                    //console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        }
    },
    mounted() {
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.userData=JSON.parse(localStorage.user)
            this.group_id=this.userData.group_id
            this.headers={
                token: localStorage.token,
                application: 'web'
            }
            this.getCustomerAscent()
        }

    }
};
</script>

<style>
nav{
    padding: 5px 30px;
}
</style>
