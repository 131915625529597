<template>
    <LayoutIntranet>
        <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>
        <div class="practiceTheme" v-if="!practice.finish">
            <div class="row justify-content-center">
                <div class="card col-md-10 px-3 px-sm-4 px-md-5 py-3">
                    <div>
                        <h2 class="text-uppercase">Práctica por tema</h2>
                    </div>
                    <hr>
                    <div class="row mb-5 align-items-center">


                        <div class="col-md-4">
                            <div class="text-warning btn btn-dark mb-1 d-block" style="border-radius:0;width:100%;">
                                <input type="hidden" name="duration" :value="duration">
                                    <p class="mb-0 pb-0" style="font-weight: bold;"> <span>Tiempo </span><span class="temporizador">
                                <span class="hour">{{tiempo.hora < 10 ? '0'+tiempo.hora : tiempo.hora}}</span><span>:</span>
                                <span class="minute">{{tiempo.minuto < 10 ? '0'+tiempo.minuto : tiempo.minuto}}</span><span>:</span>
                                <span class="second">{{tiempo.segundo < 10 ? '0'+tiempo.segundo : tiempo.segundo}}</span></span></p>
                            </div>
                            <div>
                                <button class="btn btn-dark mb-1 d-block" style="border-radius:0;width:100%;" @click="tiempo.hora=0,tiempo.minuto=0,tiempo.segundo=0">Reiniciar cronómetro</button>

                            </div>
                        </div>
                        <div class="col-md-4">
                            <button class="btn btn-dark mb-1 d-block" style="border-radius:0;width:100%;" @click="restartPractice">Reiniciar Práctica</button>
                            <button class="btn btn-dark mb-1 d-block" style="border-radius:0;width:100%;">Ver solo respuestas</button>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <input v-model="searchQuestion" type="text" class="form-control border border-dark" placeholder="Buscar pregunta N°" aria-label="question search" aria-describedby="button-addon2">
                                <button @click="search" class="btn btn-dark" type="button" id="button-addon2">Buscar</button>
                            </div>
                        </div>
                    </div>

                    <div class="row questions justify-content-center" :set="position=0">
                        <div class="col-md-12">
                            <h3 class="text-left px-0 theme_title">{{practice.title}}</h3>
                        </div>
                        <div v-for="(question) in practice.questions" :key="question"  class="py-4 px-2 question" :class="[{'active':practice.view===position},'question'+question.id+' postion'+position]" :set="position++">
                            <input type="hidden" class="questionAnswerId" :id="'question'+question.id+''" value="">

                            <div>
                                <p class="text-left d-flex question_title">
                                    <span>{{position}}.</span>
                                    <span v-html="question.question"></span>
                                </p>
                            </div>
                            <div class="mb-2">
                                <div class="group_check text-left alternative" v-for="(alternative) in question.alternatives" :key="alternative">
                                    <div class="d-flex align-items-center mb-2 labelAlter" @click="validate($event)" :theme_id="question.theme_id" :questionId="question.id" :answer="alternative.answer" :text="alternative.value">
                                        <span class="checkmark"></span>
                                        <span class="mx-3 text-left question_alternative">{{alternative.value}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5">
                                <p class="text-left text-uppercase question_code"><strong>Código de pregunta - {{question.code}}</strong></p>
                            </div>
                        </div>
                    </div>


                    <div class="text-left mb-2 row">
                        <div class="col-md-12">
                            <button @click="solution" class="btn btn-dark mx-1">Ver respuesta</button>
                        </div>
                    </div>
                    <div class="row navigation justify-content-center mb-4">
                        <div class="d-flex justify-content-between justify-content-sm-center col-12">
                            <button @click="prev" :style="[practice.view<=0?{'opacity':'0.5'}:{'opacity':'1'}]" class="btn btn-dark mx-1 mx-sm-3"><i class="lni lni-angle-double-left"></i> Atrás</button>
                            <button @click="next" :style="[practice.view>=this.practice.questions.length?{'opacity':'0.5'}:{'opacity':'1'}]" class="btn btn-dark mx-1 mx-sm-3">Siguiente <i class="lni lni-angle-double-right"></i></button>
                        </div>
                    </div>
                    <div class="row navigation justify-content-center mb-4">
                        <div class="d-flex justify-content-between justify-content-sm-center col-12">
                            <button class="btn btn-dark mx-1 mx-sm-3 py-2 px-5">Grupo</button>
                            <button class="btn btn-warning mx-1 mx-sm-3 py-2 px-5" @click="openPopupFinalizar">Finalizar Práctica</button>
                        </div>
                    </div>


                    <div class="row">
                            <div class="col-6 col-sm-6 col-md-6 col-xl-3">
                                <div class="border border-2 border-white p-3 p-md-2 p-xl-3 h-100 bg-black" style="height:100%">
                                    <p><span class="border rounded-circle text-light rounded pt-2 d-inline-block" style="width:40px;height:40px;">{{practice.totalQuestions}}</span></p>
                                    <span class="text-warning mb-0">Total de preguntas</span>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-xl-3">
                                <div class="border border-2 border-white p-3 p-md-2 p-xl-3 h-100 bg-black" style="height:100%">
                                    <p><span class="border rounded-circle text-light rounded pt-2 d-inline-block" style="width:40px;height:40px;">{{practice.totalUnanswered}}</span></p>
                                    <span class="text-warning mb-0">No contestadas</span>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-xl-3">
                                <div class="border border-2 border-white p-3 p-md-2 p-xl-3 h-100 bg-black" style="height:100%">
                                    <p><span class="border rounded-circle text-light rounded pt-2 d-inline-block" style="width:40px;height:40px;">{{practice.totalAnswer}}</span></p>
                                    <span class="text-warning mb-0">Respuestas correctas</span>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6 col-md-6 col-xl-3">
                                <div class="border border-2 border-white p-3 p-md-2 p-xl-3 h-100 bg-black" style="height:100%">
                                    <p><span class="border rounded-circle text-light rounded pt-2 d-inline-block" style="width:40px;height:40px;">{{practice.totalFails}}</span></p>
                                    <span class="text-warning mb-0">Respuestas incorrectas</span>
                                </div>
                            </div>
                    </div>


                </div>
            </div>

            <!--modal article0-->
            <div class="modal fade" id="articlePopup" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered  modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                        </div>
                    </div>
                </div>
            </div>

            <div class="popup">
                <div class="popupContent">
                    <div class="popupFinalizar">
                        <div class="popup-header">
                            <h5 class="popup-title">¿Está seguro que quiere finalizar el examén?</h5>
                            <button type="button" class="popup-close btn-close" @click="popupClose"></button>
                        </div>
                        <div class="popupal-body">
                            <button class="btn btn-warning mx-2" @click="finishPractice">Terminar</button>
                            <button type="button" class="btn btn-secondary mx-2" @click="popupClose">Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            <!--modal nuevo grupo-->
            <div class="modal fade" id="newGroupModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Nuevo grupo</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <input class="form-control" type="text" id="newGroup" v-model="newGroup" placeholder="Nuevo grupo">
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary cancel" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" class="btn btn-primary" @click="newCollection">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>

            <!--modal editar grupo-->
            <div class="modal fade" id="ListGroupPopup" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Editar grupo</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary cancel" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" class="btn btn-primary" @click="editCollection">Guardar</button>
                        </div>
                    </div>
                </div>
            </div>



        </div>



        <div class="practiceThemeResult" v-else>
            <div class="row justify-content-center">
                <div class="card p-5 col-md-8">
                    <div class="card-header">
<!--                        <div class="row">-->
<!--                            <div class="col-md-6 text-left">-->
<!--                                <p>Fecha: {{practiceFinish.fecha}}</p>-->
<!--                            </div>-->
<!--                            <div class="col-md-6">-->
<!--                                <p class="text-right">Hora: {{practiceFinish.hour}}</p>-->
<!--                            </div>-->
<!--                        </div>-->
                        <h3>Resultado de practica</h3>
                        <p>{{practiceFinish.approved==0?'Desaprobado':'Aprobado'}}</p>
                        <p>{{practiceFinish.q_answer}} ptos</p>
                        <div class="mb-3">
                            <div class="progress">
                                <div class="progress-bar bg-warning" role="progressbar" :style="{width: practice.porcentage+'%'}" :aria-valuenow="practice.porcentage" aria-valuemin="0" aria-valuemax="100">{{practice.porcentage}}%</div>
                            </div>
                        </div>

                    </div>
                    <div class="card-body">
                        <div class="text-left">
                            <p>
                                <strong>Duración:</strong>
                                <span>{{practiceFinish.duration}}</span>
                            </p>
                            <p>
                                <strong>Total de preguntas:</strong>
                                <span>{{this.practice.questions.length}}</span>
                            </p>
                            <p>
                                <strong>Total de preguntas respondidas:</strong>
                                <span>{{practiceFinish.q_answered}}</span>
                            </p>
                            <p>
                                <strong>Total de preguntas correctas:</strong>
                                <span>{{practiceFinish.q_answer}}</span>
                            </p>
                            <p>
                                <strong>Total de preguntas incorrectas:</strong>
                                <span>{{practiceFinish.q_fail}}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center" v-for="detail in details" :key="detail">
                <resultThemeQuestions :detail="detail"></resultThemeQuestions>
            </div>
        </div>
    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery'
import breadcrumbComponent from "@/components/Breadcrumb"
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import resultThemeQuestions from '@/components/ResultThemeQuestions'
import themes from "@/store/themes";
import questions from "@/store/questions";
import collection_fails from "@/store/collection_fails";

export default {
    name: 'InitialView',
    components: {
        LayoutIntranet,
        breadcrumbComponent,
        resultThemeQuestions
    },
    data() {
        return {
            breadcrumb: {
                title: 'Práctica por tema',
                navbar:[
                    {
                        title:'Practica iniciada',
                        link:''
                    }
                ]
            },
            searchQuestion:'',
            userData:null,
            group_id:null,
            headers:{},
            theme_id:'',
            type:'',
            practice:{
                title:'',
                totalQuestions:0,
                totalUnanswered:0,
                totalAnswer:0,
                totalFails:0,
                questions:[],
                view:0,
                finish:false,
                porcentage:0,
                approved: 0,
            },
            practiceFinish:[],
            details:[],

            tiempo : { hora: 0,minuto: 0,segundo: 0},
            duration:"00:00:00",


            questions:[],
            answereds:[],
            answers:[],
            fails:[],

            newGroup:''
        }
    },
    methods:{
        getTheme(){
            this.isLoading = true;
            themes.getTheme(this.theme_id).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    this.practice.title=response.register.title
                    this.getQuestionThemeId(response.register.id)
                }else if(response.status=='error'){
                    this.ascent=false
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
            this.isLoading = false;
        },
        getFail(){
            this.practice.title='Las que he fallado';
            collection_fails.getCollectionFails(this.theme_id).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    let dataFail=response.register
                    if (dataFail.questions_ids==''){
                        this.$router.push({ name: 'PracticeTheme'});
                    }
                    let ids=[];
                    if (dataFail.questions_ids.includes(',')){
                        ids=dataFail.questions_ids.split(',')
                        //let count=dataFail.questions_ids.split(',').length
                    }else {
                        ids.push(dataFail.questions_ids);
                    }
                    this.getQuestionIds(ids);
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.$router.push({ name: 'PracticeTheme'});
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        getCollection(){

        },
        getQuestionThemeId(theme_id,quantity=''){
            this.isLoading = true;
            questions.getQuestionsThemeId(theme_id,quantity).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    response.registers.forEach((element) => {
                        //console.log(element)
                        element.alternatives=JSON.parse(element.alternatives)
                    });
                    let questions=response.registers
                    this.practice.questions=questions
                    this.practice.totalQuestions=questions.length
                    this.practice.totalUnanswered=questions.length
                    this.cronometro()
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.$router.push({ name: 'PracticeTheme'});
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
            this.isLoading = false;
        },
        getQuestionIds(ids){
            questions.getQuestionsIds(ids).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    // response.registers.forEach((element) => {
                    //     element.alternatives=JSON.parse(element.alternatives)
                    // });
                    let questions=response.registers;
                    this.practice.questions=questions
                    this.practice.totalQuestions=questions.length
                    this.practice.totalUnanswered=questions.length
                    this.cronometro()
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.$router.push({ name: 'PracticeTheme'});
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        next(){
            let next=this.practice.view+1
            if(next>=this.practice.totalQuestions){return false}
            this.practice.view=next
        },
        prev(){
            let prev=this.practice.view-1
            if(prev<0){return false}
            this.practice.view=prev
        },
        search(){
            if (this.searchQuestion>0 && this.searchQuestion<=this.practice.questions.length){
                if (this.searchQuestion!=''){
                    this.practice.view=parseInt(this.searchQuestion-1)
                }
            }
        },
        solution(){
            let questionAnswer=document.getElementById('question'+this.practice.questions[this.practice.view].id).value;
            if(questionAnswer==''){
                $(".question"+this.practice.questions[this.practice.view].id+" .alternative").each(function() {
                    if($(this).find(".labelAlter").attr("answer")==1){
                        $(this).find(".labelAlter").addClass("success")
                    }
                });
                this.practice.totalUnanswered--
                this.practice.totalAnswer++
                $("#question"+this.practice.view).val(1)
            }
        },
        cronometro(){
            setInterval(() => {
                this.tiempo.segundo++
                if(this.tiempo.segundo >= 60){
                    this.tiempo.segundo = 0;
                    this.tiempo.minuto++;
                }

                // Minutos
                if(this.tiempo.minuto >= 60){
                    this.tiempo.minuto = 0;
                    this.tiempo.hora++;
                }
                let horas=this.tiempo.hora < 10 ? '0' + this.tiempo.hora : this.tiempo.hora
                let minutos=this.tiempo.minuto < 10 ? '0' + this.tiempo.minuto : this.tiempo.minuto
                let segundos=this.tiempo.segundo < 10 ? '0' + this.tiempo.segundo : this.tiempo.segundo

                this.duration=horas+":"+minutos+":"+segundos
            }, 1000);
        },

        article(article){
            $("#articlePopup .modal-body").html(article)
        },
        filterKey(e){
            var regex = new RegExp("^[0-9]+$");
            var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
            if (!regex.test(key)) {
                e.preventDefault();
                return false;
            }
        },
        restartPractice(){
            this.practice.view=0
            this.practice.totalUnanswered=this.practice.totalQuestions
            this.practice.totalAnswer=0
            this.practice.totalFails=0
            let inputId=document.querySelectorAll('.questionAnswerId')
            inputId.forEach(function (element) {
                element.value=""
            });
            let alternativeElements=document.querySelectorAll('.labelAlter')
            alternativeElements.forEach(function (alternative) {
                alternative.classList.remove("error");
                alternative.classList.remove("success");
            });

        },
        openPopupFinalizar(){
            $(".popup").fadeIn()
        },
        popupClose(){
            $(".popup").fadeOut()
        },
        validate(event){
            let questionAnswer=document.getElementById('question'+this.practice.questions[this.practice.view].id).value;
            if(questionAnswer==''){
                let answerElemente=event.target.parentElement
                let answer=event.target.parentElement.getAttribute('answer')

                if(answer==1){
                    answerElemente.classList.add("success");
                    this.practice.totalUnanswered--
                    this.practice.totalAnswer++
                    $("#question"+this.practice.questions[this.practice.view].id).val(1)
                }else{
                    answerElemente.classList.add("error");
                    $(".question"+this.practice.questions[this.practice.view].id+" .alternative").each(function() {
                        if($(this).find(".labelAlter").attr("answer")==1){
                            $(this).find(".labelAlter").addClass("success")
                        }
                    });
                    this.practice.totalUnanswered--
                    this.practice.totalFails++
                    $("#question"+this.practice.questions[this.practice.view].id).val(0)
                }
                this.practice.porcentage=(this.practice.totalAnswer/this.practice.totalQuestions)*100;
                this.practice.approved=this.practice.porcentage>=this.userData.minimun_score?1:0;
            }

        },
        finishPractice(){
            this.isLoading=true;
            this.practice.finish=true

            this.practiceFinish={
                "customer_id":this.userData.id,
                "type":this.practice.type,
                "theme_q_question":this.practice.theme_q_question,
                "q_question":this.practice.totalQuestions,
                "q_answered":this.practice.totalAnswer+this.practice.totalFails,
                "q_answer":this.practice.totalAnswer,
                "q_fail":this.practice.totalFails,
                "porcentage":Math.ceil(this.practice.porcentage),
                "approved":this.practice.approved,
                "duration":this.duration
            };
            this.practiceDetails()
        },
        practiceDetails(){
            let this_=this;
            let questions=[];
            let answereds=[];
            let answers=[];
            let fails=[];

            let q_questions=this.practice.questions.length
            let aument=0;

            this.practice.questions.forEach(function(question){
                questions.push({'id':question.id,'value':question.question});
                question.alternatives.forEach(function(alternative){
                    if (alternative.answer==1){
                        answers.push({'id':question.id,'value':alternative.value});
                    }
                })
                let answer=document.getElementById("question"+question.id);
                if (answer.value==1){
                    answereds.push({'id':question.id,'value':$(".question"+question.id+" .success.labelAlter").attr("text")})
                    fails.push({'id':question.id,'value':'null'})
                }else if (answer.value==="0"){
                    answereds.push({'id':question.id,'value':'null'})
                    fails.push({'id':question.id,'value':$(".question"+question.id+" .error.labelAlter").attr("text")})
                }else{
                    answereds.push({'id':question.id,'value':'null'})
                    fails.push({'id':question.id,'value':'null'})
                }
                aument++
                if (q_questions==aument){
                    this_.practiceDetailsJson(questions,answereds,answers,fails)
                }
            })
        },
        practiceDetailsJson(questions,answereds,answers,fails){
            console.log(answereds)
            console.log(answers)
            console.log(fails)
            this.details.push({
                "theme_id":this.theme_id,
                "theme_title":this.practice.title,
                "porcentage":Math.ceil(this.practice.porcentage),
                "questions":JSON.stringify(questions),
                "answereds":JSON.stringify(answereds),
                "answers":JSON.stringify(answers),
                "fails":JSON.stringify(fails),
            });
        }
    },
    mounted() {
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            if(this.$route.query.theme_id==undefined || this.$route.query.theme_id==''){ this.$router.push({ name: 'PracticeTheme' }); }
            if(this.$route.query.type==undefined || this.$route.query.type==''){ this.$router.push({ name: 'PracticeTheme' }); }
            this.userData=JSON.parse(localStorage.user)
            this.group_id=this.userData.group_id
            this.headers={
                token: localStorage.token,
                application: 'web'
            }

            this.theme_id=this.$route.query.theme_id
            this.type=this.$route.query.type
            if(this.type=='all'){
                this.getTheme()
            }else if(this.type=='fail'){
                this.getFail()
            }else{
                this.getCollection()
            }
        }
    }
}
</script>
<style>
    .practiceTheme .question{
        display: none;
    }
    .practiceTheme .question.active{
        display: block;
    }
    .practiceTheme .checkmark{
        position: relative;
        display: inline-block;
        background-color: #fff;
        border: 1px solid #707070;
        border-radius: 3px;
        /*margin-right: 10px;*/
        cursor: pointer;
        width: 25px !important;
        height: 25px !important;
        margin-right: 5px;
    }
    .practiceTheme span.checkmark:before {
        width: 25px;
        height: 25px;
        display: block;
        content: "";
    }
    .practiceTheme .success .checkmark:after {
        display: block;
        background-color: #299736;
    }
    .practiceTheme .error .checkmark:after {
        display: block;
        background-color: #ED1C24;
    }
    .practiceTheme .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 2px;
        height: 9px;
        border: solid #18C0C9;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .practiceTheme .checkmark:after {
        width: 100%;
        height: 100%;
        border: none;
        background-color: #299736;
        top: 0;
        left: 0;
        transform: inherit;
    }
</style>
