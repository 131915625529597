<template>
    <LayoutIntranet :isLoading="isLoading">
        <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>
        <div class="row justify-content-center">
            <div class="col-12 col-xxl-8">
                <div class="card p-3 bg-white" id="toPdf">
                    <div class="card-header">
                        <h2 class="text-center"><strong>REPORTE DE PROBABILIDAD DE ASCENSO <br> AGUILASEIS.COM</strong></h2>

                        <h5 class=""><u>INFORMACIÓN PERSONAL</u></h5>
                        <p class="text-left"><strong>SUBOFICIAL DE SEGUNDA PNP PALMA BOCANEGRA CARLOS JORGE</strong></p>

                        <div class="informe">
                            <div class="row mb-5">
                                <div class="col-md-6 text-left border border-dark p-2">
                                    <p class="mb-0"><strong>Carné: {{customer.cip}}</strong></p>
                                </div>
                                <div class="col-md-6 text-left border border-dark p-2">
                                    <p class="mb-0"><strong>DNI: {{customer.dni}}</strong></p>
                                </div>
                                <div class="col-md-6 text-left border border-dark p-2">
                                    <p class="mb-0"><strong>Celular: {{customer.phone}}</strong></p>
                                </div>
                                <div class="col-md-6 text-left border border-dark p-2">
                                    <p class="mb-0"><strong>F.EGRESO: {{ascent.date_graduate}}</strong></p>
                                </div>
                                <div class="col-md-6 text-left border border-dark p-2">
                                    <p class="mb-0"><strong>F.ULT.ASCENSO: {{ascent.date_promotion}}</strong></p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div style="background-color: #0ba0af;color: #fff;font-size: 18px;padding: 5px">TE TOCA POSTULAR EL AÑO</div>
                                    <div class="font-30 px-3 py-1  border"><strong>2023</strong></div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="card-body">
                        <div class="seccion title mb-5">
                            <h4 class="text-left"><u>TÍTULO</u></h4>
                            <div class="d-flex justify-content-around align-items-center">
                                <div class="fondoAscent w-100 border border-dark py-1"><strong>POSEE TÍTULO</strong></div>
                                <div class="w-100 border border-dark py-1">
                                    <span v-if="ascent.title==1">SÍ</span>
                                    <span v-else>NO</span>
                                </div>
                            </div>
                        </div>
                        <div class="seccion medical mb-5">
                            <h4 class="text-left"><u>FICHA MÉDICA ANUAL</u></h4>
                            <div class="d-flex justify-content-around align-items-center">
                                <div class="fondoAscent w-100 border border-dark py-1"><strong>ÚLTIMO AÑO QUE PASO FEMA</strong></div>
                                <div class="w-100 border border-dark py-1">{{ascent.fema}}</div>
                            </div>
                        </div>

                        <div class="seccion evaluation">
                            <h4 class="text-left mb-3"><u>FACTORES DE EVALUACIÓN </u></h4>
                            <div class="evaluation mb-5">
                                <h5><u>FACTOR DESEMPEÑO PROFESIONAL O TÉCNICO</u></h5>
                                <div class="d-flex justify-content-around align-items-center">
                                    <div class="fondoAscent col-8 border border-dark py-1">2022(Esta es una nota provisional)</div>
                                    <div class="col-4 border border-dark py-1">87</div>
                                </div>
                                <div class="d-flex justify-content-around align-items-center">
                                    <div class="fondoAscent col-8 border border-dark py-1">2021</div>
                                    <div class="col-4 border border-dark py-1">89</div>
                                </div>
                                <div class="d-flex justify-content-around align-items-center">
                                    <div class="fondoAscent col-8 border border-dark py-1">2020</div>
                                    <div class="col-4 border border-dark py-1">87</div>
                                </div>
                                <div class="d-flex justify-content-around align-items-center">
                                    <div class="fondoAscent col-8 border border-dark py-1">2019</div>
                                    <div class="col-4 border border-dark py-1">87</div>
                                </div>
                                <div class="d-flex justify-content-end align-items-end">
                                    <div class="fondoAscent col-4 border border-dark py-1">PROMEDIO DE FRT</div>
                                    <div class="col-4 border border-dark py-1">87.5</div>
                                </div>
                            </div>


                            <div class="evaluation mb-5">
                                <h5 class="text-uppercase"><u>FACTOR académico</u></h5>
                                <div class="d-flex justify-content-around align-items-center">
                                    <div class="fondoAscent col-8 border border-dark py-1">Curso de Capacitación Institucional</div>
                                    <div class="col-4 border border-dark py-1">20</div>
                                </div>

                                <div class="d-flex justify-content-end align-items-end">
                                    <div class="fondoAscent col-4 border border-dark py-1">PROMEDIO DE FACAD</div>
                                    <div class="col-4 border border-dark py-1">20</div>
                                </div>
                            </div>


                            <div class="evaluation mb-5">
                                <h5 class="text-uppercase"><u>factor experiencia para el servicio policial</u></h5>
                                <div class="d-flex justify-content-around align-items-center">
                                    <div class="fondoAscent col-8 border border-dark py-1">No registra</div>
                                    <div class="col-4 border border-dark py-1">0</div>
                                </div>

                                <div class="d-flex justify-content-end align-items-end">
                                    <div class="fondoAscent col-4 border border-dark py-1 text-uppercase">PROMEDIO DE fepsp</div>
                                    <div class="col-4 border border-dark py-1">0</div>
                                </div>
                            </div>

                        </div>

                        <div class="result mb-5">
                            <h4 class="text-left mb-3 text-uppercase"><u>evaluación final</u></h4>
                            <div class="d-flex justify-content-around align-items-center">
                                <div class="fondoAscent col-8 border border-dark py-1 text-uppercase">promedio de factores de evaluación</div>
                                <div class="col-4 border border-dark py-1">60.190</div>
                            </div>
                            <div class="d-flex justify-content-around align-items-center">
                                <div class="fondoAscent col-8 border border-dark py-1 text-uppercase">nota de examen de conocimientos</div>
                                <div class="col-4 border border-dark py-1">100.000</div>
                            </div>
                            <div class="d-flex justify-content-around align-items-center">
                                <div class="fondoAscent col-8 border border-dark py-1 text-uppercase bg-dark text-warning">Nota final</div>
                                <div class="col-4 border border-dark py-1">72.133</div>
                            </div>
                            <div class="d-flex justify-content-around align-items-center">
                                <div class="fondoAscent col-8 border border-dark py-1 text-uppercase bg-dark text-warning">puesto referencial basado en el cuadro de mérito del 2022</div>
                                <div class="col-4 border border-dark py-1">Entre el 412 al 912</div>
                            </div>
                        </div>


                        <div class="mb-5">
                            <h2 class="text-uppercase">calcular otra nota de examen</h2>
                            <div class="row justify-content-center">
                                <div class="col-12 col-md-6">
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" placeholder="NOTA" aria-label="Recipient's username" aria-describedby="button-addon2">
                                        <button class="btn btn-outline-secondary bg-dark text-white" type="button" id="button-addon2">Calcular</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="mb-5">
                            <h4 class="text-left mb-3 text-uppercase"><u>Recomendaciones</u></h4>
                            <div class="mb-3">
                                <div class="fondoAscent col-12 border border-dark py-1 text-uppercase">sobre su título</div>
                                <div class="col-12 border border-dark py-1 text-uppercase">TODO ESTA CORRECTO</div>
                            </div>
                            <div class="mb-3">
                                <div class="fondoAscent col-12 border border-dark py-1 text-uppercase">sobre la ficha médica anual</div>
                                <div class="col-12 border border-dark py-1 text-uppercase">"no" es necesario que pases fema este 2023</div>
                            </div>
                            <div class="mb-3">
                                <div class="fondoAscent col-12 border border-dark py-1 text-uppercase">recomendaciones generales</div>
                                <div class="col-12 border border-dark py-1 text-uppercase">debes obtener la máxima nota en el examen de conocimientos</div>
                                <div class="col-12 border border-dark py-1 text-uppercase">debes evitar las sanciones hasta el 31 dic20223, de lo contrario reducirás tu puntaje.</div>
                                <div class="col-12 border border-dark py-1 text-uppercase">si realizas y anexas a tu legajo un curso de especialización institucional antes del 31jul2023, podras mejorar tu probabilidad de ascender.</div>
                            </div>
                        </div>

                        <div>
                            <h5>¿Tienes dudas en tu reporte?</h5>
                            <div>
                                <a class="btn btn-dark mx-1">Contactar al asesor</a>
                                <a class="btn btn-dark mx-1" @click="exportToPDF">Descargar PDF</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import breadcrumbComponent from "@/components/Breadcrumb"
import ascent from "@/store/ascent";
import ascent_options from "@/store/ascent_options";
import customers from "@/store/customers";
import factors from "@/store/factors";
import factor_options from "@/store/factor_options";
import html2pdf from "html2pdf.js";
export default {
    name: 'ReportAscentIndexView',
    components: {
        LayoutIntranet,
        breadcrumbComponent,
    },
    data() {
        return {
            breadcrumb: {
                title: 'Reporte ascenso',
                navbar: [
                    {
                        title: 'Simulacro',
                        link: ''
                    },
                    {
                        title: 'Iniciar',
                        link: ''
                    }
                ]
            },
            isLoading: false,
            userData:{},
            customer:{},
            ascent: {},
            ascent_options: {},
            factors:[],
            factor_options:[],


            report:{

            }

        }
    },
    methods:{
        getAscent(){
            ascent.getAscentCustomer().then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    console.log(response)
                    this.ascent=response.register;
                    this.getAscentOptions()
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.$router.push({ name: 'Dashboard'});
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        getAscentOptions(){
            ascent_options.getAscentOption(this.ascent.id).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    console.log(response)
                    this.ascent_options=response.register;
                    this.getFactors()
                    this.getFactorOptions()
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.$router.push({ name: 'Dashboard'});
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        getFactors(){
            factors.getFactors().then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    console.log(response)
                    this.factors=response.registers;
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.$router.push({ name: 'Dashboard'});
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        getFactorOptions(){
            factor_options.getFactorOptionsGrade(this.customer.grade_id).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    console.log(response)
                    this.factor_options=response.registers;
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.$router.push({ name: 'Dashboard'});
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },


        exportToPDF() {
            html2pdf(document.getElementById("toPdf"), {
                margin: 1,
                filename: "ReporteAscenso.pdf",
            });
        },
    },
    mounted() {

        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.userData=JSON.parse(localStorage.user)
            this.headers={
                token: localStorage.token,
                application: 'web'
            }
            customers.getShow().then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    this.customer=response.register;
                    this.getAscent();
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.$router.push({ name: 'Dashboard'});
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })


        }
    }
}
</script>
<style>
    .fondoAscent{
        background-color: #0ba0af;
        color: #fff;
    }
</style>
