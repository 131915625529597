
export default {

    valiPermission(permision,listPermission) {
        let autorize=false;
        let list=listPermission.split(",");
        if(list.indexOf(permision) !== -1) { autorize=true; }
        return autorize;
    }
}
