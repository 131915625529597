<template>
    <LayoutIntranet :isLoading="isLoading">
        <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>
        <div class="row">
            <div class="col-12">
                <h1>Por favor debe adjuntar su RIPER</h1>
                <input class="form-control" type="file" id="formFile">
            </div>
        </div>
    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import breadcrumbComponent from "@/components/Breadcrumb"
export default {
    name: 'AscentReportRiperView',
    components: {
        LayoutIntranet,
        breadcrumbComponent,
    },
    data() {
        return {
            breadcrumb: {
                title: 'Reporte ascenso',
                navbar: [
                    {
                        title: 'Reporte ascenso',
                        link: ''
                    },
                    {
                        title: 'Riper',
                        link: ''
                    }
                ]
            },
            isLoading: false,
            userData:{},
        }
    },
    mounted() {
        this.userData=JSON.parse(localStorage.user)
        this.headers={
            token: localStorage.token,
            application: 'web'
        }
    }
}
</script>
<style>
</style>
