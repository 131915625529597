import axios from 'axios';

export default {
    getCollectionFails(theme_id) {
        let userData=JSON.parse(localStorage.user);
        let params={
            theme_id:theme_id,
            customer_id:userData.id
        }
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.get(process.env.VUE_APP_API_URL+'collection-fails',{headers,params}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    },
    postCollectionsFailsThemes(theme_ids){
        let userData=JSON.parse(localStorage.user);
        let params={
            theme_ids:theme_ids,
            customer_id:userData.id
        }
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.post(process.env.VUE_APP_API_URL+'collection-fails/theme_ids', params,{headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    }
}
