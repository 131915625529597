<template>
    <LayoutIntranet>
        <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>

    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import breadcrumbComponent from "@/components/Breadcrumb"
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
//import $ from 'jquery';

export default {
    name: 'NoticeView',
    data() {
        return {
            breadcrumb: {
                title: 'Generador de práctica',
                navbar:[
                    {
                        title: 'Práctica',
                        link:''
                    },
                    {
                        title: 'Practica iniciada',
                        link:''
                    }
                ]
            },

        }
    },
    components: {
        LayoutIntranet,
        breadcrumbComponent
    },
    methods:{

    },
    mounted() {
    }
}
</script>
<style>
</style>
