<template>
    <LayoutIntranet :isLoading="isLoading">
        <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>
        <div class="row justify-content-center">
             <div class="col-12 col-md-10 col-xxl-8">
                <div class="card">
                    <div class="card-header">
                        <h1 class="card-title">Cuadro de méritos</h1>
                        <p>{{title}}</p>
                    </div>
                    <div class="card-body">
                        <table class="table table-bordered">
                            <tr class="bg-dark text-white font-20" :set="pos=0">
                                <th>Puesto</th>
                                <th>Nickname</th>
                                <th>Correctas</th>
                                <th>Incorrectas</th>
                                <th>No respondidas</th>
                                <th>Puntaje</th>
                            </tr>
                            <tr v-for="score in scores" :key="score" class="font-18" :class="[score.customer_id==userData.id?'bg-warning':'']" :set="pos++">
                                <td class="py-2">{{pos}}</td>
                                <td class="py-2">{{score.nickname}}</td>
                                <td class="py-2">{{score.q_answer}}</td>
                                <td class="py-2">{{score.q_fail}}</td>
                                <td class="py-2">{{score.q_question-score.q_asnwered}}</td>
                                <td class="py-2">{{score.porcentage}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
             </div>
        </div>

    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import breadcrumbComponent from "@/components/Breadcrumb"
import intranet_practice from "@/store/intranet_practice";
export default {
    name: 'DashboardView',
    components: {
        LayoutIntranet,
        breadcrumbComponent,
    },
    data() {
        return {
            breadcrumb: {
                title: 'Practica global',
                navbar: [
                    {
                        title: 'Practica global',
                        link: ''
                    },
                    {
                        title: 'Score',
                        link: ''
                    }
                ]
            },
            isLoading: true,
            userData:{},
            scores:[],
            code:'',
            title:'',
        }
    },
    mounted() {
        this.userData=JSON.parse(localStorage.user)
        this.headers={
            token: localStorage.token,
            application: 'web'
        }
        this.isLoading=false;
        this.code=this.$route.params.code;
        intranet_practice.getIntranetPracticesScore(this.code).then(response => {
            this.loading = false;
            this.scores = response.data.registers
            this.title=this.scores[0].practice_title
            console.log(this.scores)
        }).catch(error => {
            console.error(error);
        });
    }
}
</script>
<style>
</style>
