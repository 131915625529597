<template>
    <div class="wrapper" :style="{
      'background': 'url('+backgroundImage+')',
      'background-size':'cover'
    }">
        <div class="practice section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
            <div class="container-fluid">
                <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                    <div class="col mx-auto">
                        <div class="mb-4 text-center">
                            <img src="@/assets/images/logo.png" width="300" alt="" />
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="border p-4 rounded">

                                    <div class="text-center">
                                        <h3 class="">Iniciar sesión</h3>
                                        <p>Simulador de Examen de Ascenso 2023 - Promoción 2024</p>
                                    </div>

                                    <div class="login-separater text-center mb-4">
                                        <hr/>
                                    </div>
                                    <div v-if="error==true" class="alert alert-danger border-0 bg-danger alert-dismissible fade show">
                                      <div class="text-white">{{error_msg}}</div>
                                      <!-- <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> -->
                                    </div>
                                    <div class="form-body">
                                        <form class="row g-3" v-on:submit.prevent="login">
                                            <div class="col-12">
                                                <label for="inputEmailAddress" class="form-label">Correo</label>
                                                <input v-model="email" type="email" class="form-control" id="inputEmailAddress" placeholder="Correo electronico">
                                            </div>
                                            <div class="col-12">
                                                <label for="inputChoosePassword" class="form-label">Contraseña</label>
                                                <div class="input-group" id="show_hide_password">
                                                    <input v-model="password" type="password" class="form-control border-end-0" id="inputChoosePassword" placeholder="Contraseña"> <a href="javascript:;" class="input-group-text bg-transparent"><i class='bx bx-hide'></i></a>
                                                </div>
                                            </div>
                                            <div class="col-md-12 text-center">
                                              <a href="authentication-forgot-password.html">Olvidaste tu contraseña</a>
                                            </div>
                                            <div class="col-md-12 text-center">
                                              <router-link :to="{ name: 'Register'}">
                                                Registrate
                                              </router-link>
                                            </div>
                                            <div class="col-12">
                                                <div class="d-grid">
                                                    <button type="submit" class="btn btn-warning"><i class="bx bxs-lock-open"></i>Ingresar</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end row-->
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import $ from 'jquery';

export default {
    name: 'LoginView',
    components: {
    },
    data:function () {
        return {
            backgroundImage: process.env.VUE_APP_API_UPLOADS+'60e447a381e35.jpg',
            email:'',
            password:'',
            error:false,
            error_msg:''
        }
    },
    methods:{
        login(){
          //this.$router.push('intranet/dashboard');
            let json={
              "email":this.email,
              "password":this.password,
              "application":'web',
            };
            axios.post(process.env.VUE_APP_API_URL+'login', json)
              .then(data=>{
                if (data.data.status=='success'){
                    let register=data.data.register;
                    if (register.status=='A'){
                        let applications=[];
                        if (register.applications.includes(',')){
                            applications=register.applications.split(',');
                        }else{
                            applications.push(register.appications)
                        }

                        if (applications.includes('web')){
                            localStorage.token=data.data.token
                            localStorage.user=JSON.stringify({
                                id:data.data.register.id,
                                nickname:data.data.register.nickname,
                                dni:data.data.register.dni,
                                grade_id:data.data.register.grade_id,
                                group_id:data.data.register.group_id,
                                gender:data.data.register.gender,
                                suscription:data.data.register.suscription,
                                ascent:data.data.register.ascent,
                                minimun_score:data.data.register.minimun_score,
                                minutes:data.data.register.minutes,
                                permissions:data.data.register.permissions,
                                grade_name:data.data.register.grade_name,
                            })
                            this.$router.push({ name: 'Dashboard'});
                        }
                    }
                }else{
                  this.error=true;
                  let message=data.data.message;
                  console.log(message)
                  if(Array.isArray(message.email)){
                    this.error_msg="El correo electronico es obligatorio";
                  }else{
                    this.error_msg=message;
                  }
                }
              })
        }
    },
    mounted() {
      $("#show_hide_password a").on('click', function (event) {
        event.preventDefault();
        if ($('#show_hide_password input').attr("type") == "text") {
          $('#show_hide_password input').attr('type', 'password');
          $('#show_hide_password i').addClass("bx-hide");
          $('#show_hide_password i').removeClass("bx-show");
        } else if ($('#show_hide_password input').attr("type") == "password") {
          $('#show_hide_password input').attr('type', 'text');
          $('#show_hide_password i').removeClass("bx-hide");
          $('#show_hide_password i').addClass("bx-show");
        }
      });
    }
}
</script>
