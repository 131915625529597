<template>
    <LayoutIntranet>
        <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>
        <div class="row justify-content-center practiceThemeView">
            <div class="col-12 col-md-10 col-xl-8 col-xxl-6">
                    <div class="card border border-warning bg-black text-white">
                        <div class="card-body">
                            <p>Realiza una práctica con todas las preguntas del tema que elijas.</p>
                            <p>Selecciona un tema</p>
                            <select  class="form-select" @change="changeTheme($event)" v-model="theme_id">
                                <option value="">Seleccionar tema</option>
                                <option v-for="theme in themes" :key="theme" :value="theme.id" :countQuestions="theme.count_questions">{{theme.count_questions}} - {{theme.title}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="card" v-if="listView">
                        <div class="card-body">
                            <div class="groupAll">
                                <div class="row justify-content-center align-items-center" id="collectAll">
                                    <div class="col-3 col-sm-2 col-xl-2 d-flex justify-content-end"></div>
                                    <div class="col-2 col-sm-1 col-xl-1 text-center">
                                        <div class="form-group check">
                                            <label for="collectionall">
                                                <input class="d-none" type="radio" id="collectionall" v-model="type" value="all">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-7 col-sm-6 col-xl-4 col-xxl-5 text-left">
                                        <label for="collectall">({{totalQuestions}}) Todas las preguntas</label>
                                    </div>
                                </div>
                            </div>
                            <div class="groupFails">
                                <div class="row justify-content-center align-items-center" id="collectfail">
                                    <div class="col-3 col-sm-2 col-xl-2 d-flex justify-content-end"></div>
                                    <div class="col-2 col-sm-1 col-xl-1 text-center">
                                        <div class="form-group check">
                                            <label for="collectionfail">
                                                <input class="d-none" type="radio" id="collectionfail" v-model="type" value="fail">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-7 col-sm-6 col-xl-4 col-xxl-5 text-left">
                                        <label for="collectionfail">({{totalFails}}) Los que he fallado</label>
                                    </div>
                                </div>
                            </div>
                            <div class="groupThemes">
                                <div v-for="(collection) in collectionsRow" :key="collection" class="row justify-content-center align-items-center" :id="'collect'+collection.id">
                                    <div class="col-3 col-sm-2 col-xl-2 d-flex justify-content-end">
                                        <a v-if="collection.delete" href="javascript:void(0)" @click="deleteCollectionPopup(collection.id)" class="font-24 text-center deleteGroup mx-1" data-bs-toggle="modal" data-bs-target="#deleteGroupModal">
                                            <i class="lni lni-close text-danger"></i>
                                        </a>
                                        <a v-if="collection.edit" href="javascript:void(0)" class="font-24 editGroup" @click="editCollectionPopup(collection.id,collection.title)"  data-bs-toggle="modal" data-bs-target="#editGroupModal">
                                            <i class="lni lni-pencil  text-warning"></i>
                                        </a>
                                    </div>
                                    <div class="col-2 col-sm-1 col-xl-1 text-center">
                                        <div class="form-group check">
                                            <label :for="'collection'+collection.id">
                                                <input class="d-none" type="radio" :id="'collection'+collection.id"  v-model="type" :value="collection.id">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-7 col-sm-6 col-xl-4 col-xxl-5 text-left">
                                        <label :for="'collection'+collection.id">({{collection.count}}) {{collection.title}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="groupNew">
                                <div class="row justify-content-center align-items-center">
                                    <div class="col-3 col-sm-2 col-xl-2 d-flex justify-content-end"></div>
                                    <div class="col-2 col-sm-1 col-xl-1 text-center">
                                        <div class="form-group check">
                                            <span  class="font-22 newGroupButton" data-bs-toggle="modal" data-bs-target="#newGroupModal">
                                                <i class="lni lni-circle-plus text-warning"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-7 col-sm-6 col-xl-4 col-xxl-5 text-left">
                                        <label >Nuevo grupo</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer" v-show="btnSubmit">
                            <button class="btn btn-warning text-white" @click="send">
                                Iniciar Práctica
                            </button>
                        </div>
                    </div>
            </div>
        </div>




        <!--modal nuevo grupo-->
        <div class="modal fade" id="newGroupModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Nuevo grupo</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <input class="form-control" type="text" id="newGroup" v-model="newGroup" placeholder="Nuevo grupo">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary cancel" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-primary" @click="newCollection">Guardar</button>
                    </div>
                </div>
            </div>
        </div>

        <!--modal editar grupo-->
        <div class="modal fade" id="editGroupModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Editar grupo</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <input class="form-control" type="hidden" v-model="editGroup.id">
                        <input class="form-control" type="text" v-model="editGroup.title">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary cancel" data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-primary" @click="editCollection">Guardar</button>
                    </div>
                </div>
            </div>
        </div>

        <!--modal eliminar grupo-->
        <div class="modal fade" id="deleteGroupModal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <form @submit.prevent="signInButtonPressed">
                    <div class="modal-header">
                        <h5 class="modal-title">Eliminar grupo</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <input class="form-control" type="hidden" id="deleteGroup" v-model="deleteGroup">
                        <p>Esta seguro que desea eliminar su colección?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary cancel"  data-bs-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-danger" @click="deleteCollection">Eliminar</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>

    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery'
import breadcrumbComponent from "@/components/Breadcrumb"
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import group_themes from "@/store/group_themes";
import themes from "@/store/themes";
import collection_fails from "@/store/collection_fails";
import collections from "@/store/collections";

export default {
    name: 'IndexView',
    data() {
        return {
            breadcrumb: {
                title: 'Práctica por tema',
                navbar:[
                    {
                        title:'Practica un tema',
                        link:''
                    }
                ]
            },
            listView:false,
            group_theme_ids:[],
            themes:[],
            userData:null,
            group_id:null,
            headers:{},
            btnSubmit: false,
            totalQuestions:0,
            totalFails:0,
            collectionsRow:[],
            theme_id:'',
            type:'',
            deleteGroup:'',
            newGroup:'',
            editGroup:{
                id:'',
                title:''
            }
        }
    },
    components: {
        LayoutIntranet,
        breadcrumbComponent
    },
    methods:{
        getGroupThemes(){
            this.isLoading = true;
            group_themes.getGroupThemes().then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    response.registers.forEach((register) => {
                        this.group_theme_ids.push(register.theme_id)
                    });
                    this.getThemes(this.group_theme_ids)
                }else if(response.status=='error'){
                    this.ascent=false
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        getThemes(theme_ids){
            this.isLoading = true;
            themes.postThemes(theme_ids).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    this.themes=response.registers
                    //console.log(this.themes)
                    this.themes.forEach((register) => {
                        this.totalQuestions=this.totalQuestions+register.count_questions
                    });
                }else if(response.status=='error'){
                    this.ascent=false
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
            this.isLoading = false;
        },

        changeTheme(event){
            this.isLoading = true;
            if (event.target.value!=''){
                var options = event.target.options
                if (options.selectedIndex > -1) {
                    var countQuestions = options[options.selectedIndex].getAttribute('countQuestions');
                }
                this.totalQuestions=countQuestions;
                this.collectionFails()
                this.btnSubmit=true
                this.listView=true
            }else{
                this.collectionsRow=[]
                this.btnSubmit=false
            }
        },
        collectionFails(){
            this.isLoading = true;
            collection_fails.getCollectionFails(this.theme_id).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    let dataFail=response.register
                    let count=dataFail.questions_ids.split(',').length
                    this.totalFails=count;
                    this.collections()
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.totalFails=0;
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                    this.collections()
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
            this.isLoading = false;
        },
        collections(){
            this.isLoading = true;
            this.collectionsRow=[]

            collections.getCollections(this.theme_id).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    var collectionsRow=this.collectionsRow
                    let collections=response.registers
                    $.each( collections, function( index,collection ) {
                        let countQ=0
                        if (collection.questions_id!=null && collection.questions_id!=''){
                            countQ=collection.questions_id.split(',').length
                        }
                        collectionsRow.push({
                            id:collection.id,
                            count:countQ,
                            title:collection.title,
                            edit:true,
                            delete:true
                        })
                    });
                }else if(response.status=='error'){
                    this.error=true;
                    this.error_msg=response.message;
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        deleteCollectionPopup(collection_id){
            this.deleteGroup=collection_id
        },
        deleteCollection(){
            collections.deleteCollection(this.deleteGroup).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    this.deleteGroup=''
                    $("#deleteGroupModal .cancel").click()
                    let params={
                        theme_id:this.theme_id,
                        customer_id:this.userData.id
                    }
                    this.collectionFails(params)
                }else if(response.status=='error'){
                    this.error=true;
                    this.error_msg=response.message;
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        newCollection(){
            let json = {
                "theme_id": this.theme_id,
                "customer_id": this.userData.id,
                "title": this.newGroup,
                "questions_id": ''
            };
            let params={
                theme_id:this.theme_id,
                customer_id:this.userData.id
            }
            collections.storeCollection(json).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    this.newGroup=''
                    $("#newGroupModal .cancel").click()
                    $("#collectnew").remove()
                    this.collections(params)
                }else if(response.status=='error'){
                    this.error=true;
                    this.error_msg=response.message;
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })


        },
        editCollectionPopup(collection_id,title){
            this.editGroup.id=collection_id;
            this.editGroup.title=title;
        },
        editCollection(){
            let params={
                theme_id:this.theme_id,
                customer_id:this.userData.id
            }
            let json={title:this.editGroup.title}

            collections.updateCollection(this.editGroup.id,json).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    this.editGroup.id='';
                    this.editGroup.title='';
                    $("#editGroupModal .cancel").click()
                    this.collections(params)
                }else if(response.status=='error'){
                    this.error=true;
                    this.error_msg=response.message;
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })

        },
        send(){
            let param={
                theme_id:this.theme_id,
                type:this.type
            }
            console.log(param)
            this.$router.push({ name: 'PracticeThemeInit', query:param});
        }

    },
    mounted() {
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.userData=JSON.parse(localStorage.user)
            this.group_id=this.userData.group_id
            this.headers={
                token: localStorage.token,
                application: 'web'
            }
            this.getGroupThemes()
        }
    }
}
</script>
<style>
    .practiceThemeView .checkmark {
        position: relative;
        width: 25px;
        height: 25px;
        display: block;
        background-color: #fff;
        border: 1px solid #707070;
        border-radius: 3px;
        margin-right: 10px;
    }
    .practiceThemeView  .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 2px;
        height: 9px;
        border: solid #18C0C9;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .practiceThemeView  .checkmark:after {
        left: 8px;
        top: 2px;
        width: 7px;
        height: 15px;
        border: solid #000;
        border-width: 0 3px 3px 0;
    }
    .practiceThemeView  input:checked ~ .checkmark:after {
        display: block;
    }
</style>
