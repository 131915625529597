<template>
    <LayoutIntranet :isLoading="isLoading">
        <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>
        <div class="row justify-content-center">
            <div class="card col-md-12">
                <div class="card-header">
                    <h3>Historial de autoevaluaciones</h3>
                </div>
                <div class="card-body">

                </div>
            </div>
        </div>
        <div class="row" :set="aument=0">
            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3"  v-for="practice in practices" :key="practice" :set="aument++">
                <div class="card">
                    <div class="card-header">
                        <p>{{aument}}.- {{practice.approved==1?'Aprobado':'Desaprobado'}}</p>
                        <p :set="dateHour=practice.created_at.split(' ')">Fecha: {{dateHour[0]}} Hora: {{dateHour[1]}}</p>
                    </div>
                    <div class="card-body">
                        <p>Cantidad de preguntas: {{practice.q_question}}</p>
                        <p>Calificación: {{practice.porcentage}}</p>
                    </div>
                    <div class="card-footer">
                        <router-link :to="{ name: 'SimulationDetail',params:{id:practice.id}}" class="btn btn-warning">Ver detalle</router-link>
                    </div>
                </div>
            </div>

        </div>

    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
//import $ from 'jquery';
import breadcrumbComponent from "@/components/Breadcrumb"
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import permissionHelper from "@/helpers/validatePermission";
import simulations from "@/store/simulations";

export default {
    name: 'practiceHistory',
    components: {
        LayoutIntranet,
        breadcrumbComponent,

    },
    data() {
        return {
            isLoading:false,
            breadcrumb: {
                title: 'Generador de práctica',
                navbar:[
                    {
                        title: 'Práctica',
                        link:''
                    },
                    {
                        title: 'Historial',
                        link:''
                    }
                ]
            },
            userData:null,
            group_id:null,
            headers:{},
            practices:[],
        }
    },
    methods:{
        getPractices(){
            this.isLoading = true;
            simulations.getSimulationCustomer().then(response=>{
                if (response.status=='success'){
                    console.log(response.register)
                    this.practices=response.registers
                    this.isLoading = false;
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.$router.push({ name: 'PracticeTheme'});
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        }
    },
    mounted() {
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.userData=JSON.parse(localStorage.user)
            this.group_id=this.userData.group_id
            this.headers={
                token: localStorage.token,
                application: 'web'
            }
            this.getPractices()
            //this.practice_id=this.$route.params.id
            if (!permissionHelper.valiPermission('autoevaluacion',this.userData.permissions) || this.userData.suscription=='I'){
                this.$router.push({ name: 'Dashboard'});
            }
        }

    }
}
</script>
<style>
</style>
