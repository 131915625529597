<template>
    <LayoutIntranet :isLoading="isLoading">
        <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>
        <div class="practice">
            <div class="row justify-content-center">
                <div class="card col-md-10 p-5">
                    <div class="mb-3">
                        <h1 style="font-weight: bold;" class="text-uppercase">Práctica</h1>
                    </div>
                    <div class="row mb-2 align-items-center">
                        <div class="col-md-7">
                            <div class="time">
                                <input type="hidden" name="duration" :value="duration">
                                <p class="text-left" style="font-size: 22px;"> <span>Tiempo </span><span class="temporizador">
                                    <span class="hour">{{tiempo.hora < 10 ? '0'+tiempo.hora : tiempo.hora}}</span><span>:</span>
                                    <span class="minute">{{tiempo.minuto < 10 ? '0'+tiempo.minuto : tiempo.minuto}}</span><span>:</span>
                                    <span class="second">{{tiempo.segundo < 10 ? '0'+tiempo.segundo : tiempo.segundo}}</span></span>
                                </p>
                            </div>
                        </div>
                        <div class="col-1">
                        </div>
                        <div class="col-md-3">
                            <div>
                                <div class="input-group mb-3">
                                    <input v-model="searchQuestion" type="text" class="form-control" placeholder="Buscar pregunta N°" aria-label="question search" aria-describedby="button-addon2">
                                    <button @click="search" class="btn btn-outline-secondary" type="button" id="button-addon2">Buscar</button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <h3 class="text-left theme_title">{{practice.title}}</h3>

                    <hr>

                    <div class="row questions justify-content-center" :set="position=0">
                        <div v-for="(question) in practice.questions" :key="question"  class="py-4 px-2 question" :class="[{'active':practice.view===position},'question'+question.id+' postion'+position]" :set="position++">
                            <input type="hidden" class="questionAnswerId" :id="'question'+question.id+''" value="">

                            <div>
                                <p class="text-left d-flex question_title">
                                    <span>{{position}}.</span>
                                    <span v-html="question.question"></span>
                                </p>
                            </div>
                            <div class="mb-2">
                                <div class="group_check text-left alternative" v-for="(alternative) in question.alternatives" :key="alternative">
                                    <div class="d-flex align-items-center mb-2 labelAlter" @click="validate($event)" :theme_id="question.theme_id" :questionId="question.id" :answer="alternative.answer" :text="alternative.value">
                                        <span class="checkmark"></span>
                                        <span class="mx-3 text-left question_alternative">{{alternative.value}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5">
                                <p class="text-left text-uppercase question_code"><strong>Código de pregunta - {{question.code}}</strong></p>
                            </div>
                        </div>
                    </div>

                    <div class="row navigation justify-content-center mb-3">
                        <div class="d-flex justify-content-center col-md-8">
                            <button @click="prev" :style="[practice.view<=0?{'opacity':'0.5'}:{'opacity':'1'}]" class="btn btn-dark"><i class="lni lni-angle-double-left mx-1"></i> Atrás</button>
                            <button @click="next" :style="[practice.view>=(this.practice.questions.length-1)?{'opacity':'0.5'}:{'opacity':'1'}]" class="btn btn-dark mx-1">Siguiente <i class="lni lni-angle-double-right"></i></button>
                        </div>
                    </div>
                    <div class="row navigation justify-content-center mb-5">
                        <div class="d-flex justify-content-center  col-md-6">
                            <button class="btn btn-warning mx-3 py-2 px-5" @click="openPopupFinalizar">Finalizar Práctica</button>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col-6 col-sm-6 col-md-6 col-xl-3">
                            <div class="border border-2 border-white p-3 p-md-2 p-xl-3 h-100 bg-black">
                                <span class=" rounded-circle border border-2 border-warning d-inline-block text-white" style="width: 50px;height: 50px;line-height: 3;">{{practice.totalQuestions}}</span>
                                <p class="mt-1 text-warning mb-0 ">Total de preguntas</p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-xl-3">
                            <div class="border border-2 border-white p-3 p-md-2 p-xl-3 h-100 bg-black">
                                <span class=" rounded-circle border border-2 border-warning d-inline-block text-white" style="width: 50px;height: 50px;line-height: 3;">{{practice.totalUnanswered}}</span>
                                <p class="mt-1 text-warning mb-0 ">No contestadas</p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-xl-3">
                            <div class="border border-2 border-white p-3 p-md-2 p-xl-3 h-100 bg-black">
                                <span class=" rounded-circle border border-2 border-warning d-inline-block text-white" style="width: 50px;height: 50px;line-height: 3;">{{practice.totalAnswer}}</span>
                                <p class="mt-1 text-warning mb-0 ">Respuestas correctas</p>
                            </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-6 col-xl-3">
                            <div class="border border-2 border-white p-3 p-md-2 p-xl-3 h-100 bg-black">
                                <span class=" rounded-circle border border-2 border-warning d-inline-block text-white" style="width: 50px;height: 50px;line-height: 3;">{{practice.totalFails}}</span>
                                <p class="mt-1 text-warning mb-0 ">Repuestas incorrectas</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>


        <div class="popup">
            <div class="popupContent">
                <div class="popupFinalizar">
                    <div class="popup-header">
                        <h5 class="popup-title">¿Está seguro que quiere finalizar el examén?</h5>
                        <button type="button" class="popup-close btn-close" @click="popupClose"></button>
                    </div>
                    <div class="popupal-body">
                        <button class="btn btn-warning mx-2" @click="finishPractice">Terminar</button>
                        <button type="button" class="btn btn-secondary mx-2" @click="popupClose">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>

    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery';
import breadcrumbComponent from "@/components/Breadcrumb"
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import group_themes from "@/store/group_themes";
import themes from "@/store/themes";
import questions from "@/store/questions";
import collection_fails from "@/store/collection_fails";
import practices from "@/store/practices";
import practice_details from "@/store/practice_details";

export default {
    name: 'practiceInit',
    components: {
        LayoutIntranet,
        breadcrumbComponent,
    },
    data() {
        return {
            isLoading:true,
            breadcrumb: {
                title: 'Generador de práctica',
                navbar:[
                    {
                        title: 'Práctica',
                        link:''
                    },
                    {
                        title: 'Practica iniciada',
                        link:''
                    }
                ]
            },
            userData:null,
            group_id:null,
            headers:{},
            practice:{
                title:'',
                totalQuestions:0,
                totalUnanswered:0,
                totalAnswer:0,
                totalFails:0,
                questions:[],
                view:0,
                type: null,
                quantityExam:null,
                approved:0,
                porcentage:0,
                theme_q_question:''
            },
            searchQuestion:'',
            tiempo : { hora: 0,minuto: 0,segundo: 0},
            duration:"00:00:00",

            theme_ids:[],
            group_theme_id:[],
            themes:[]
        }
    },
    methods:{
        loadPractice(){
            if(this.practice.type=='quantity'){
                console.log("quantity")
                this.getGroupThemes(this.practice.type)
            }else if(this.practice.type=='fails'){
                console.log("fails")
                this.getGroupThemes(this.practice.type)
            }else if(this.practice.type=='all'){
                this.getGroupThemes(this.practice.type)
                console.log("all")
            }else{
                this.$router.push({ name: 'Practice'});
            }
        },
        getGroupThemes(practiceType){
            this.isLoading = true;
            group_themes.getGroupThemes().then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    response.registers.forEach((register) => {
                        this.theme_ids.push(register.theme_id)
                        this.group_theme_id[register.theme_id]=register;
                        this.themes[register.theme_id]=register.theme_title
                    });
                    console.log(this.theme_ids)
                    console.log(this.group_theme_id)
                    if(practiceType=='quantity'){
                        this.theme_ids.forEach((theme_id) => {
                            if(this.practice.quantityExam=='all'){
                                this.getQuestionsThemeId(theme_id)
                            }else{
                                if(this.practice.quantityExam==10){
                                    if(this.group_theme_id[theme_id].q_question_practice_ten==0){return}
                                    this.getQuestionsThemeId(theme_id,this.group_theme_id[theme_id].q_question_practice_ten)
                                }
                                if(this.practice.quantityExam==20){
                                    if(this.group_theme_id[theme_id].q_question_practice_twenty==0){return}
                                    this.getQuestionsThemeId(theme_id,this.group_theme_id[theme_id].q_question_practice_twenty)
                                }
                                if(this.practice.quantityExam==30){
                                    if(this.group_theme_id[theme_id].q_question_practice_thirty==0){return}
                                    this.getQuestionsThemeId(theme_id,this.group_theme_id[theme_id].q_question_practice_thirty)
                                }
                                if(this.practice.quantityExam==50){
                                    if(this.group_theme_id[theme_id].q_question_practice_fifty==0){return}
                                    this.getQuestionsThemeId(theme_id,this.group_theme_id[theme_id].q_question_practice_fifty)
                                }
                            }
                        });
                    }else if(practiceType=='fails'){
                        this.getCollectionFails(this.theme_ids)
                    }else if (practiceType=='all'){
                        let q_questions=this.$route.query.q_questions
                        console.log(JSON.parse(q_questions))
                        for (const array of JSON.parse(q_questions)){
                            this.getQuestionsThemeId(array.theme_id,array.q_question)
                        }
                    }
                    this.cronometro()
                }else if(response.status=='error'){
                    console.log(response.message)
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        getThemes(theme_ids){
            this.isLoading = true;
            themes.postThemes(theme_ids).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    this.themes=response.registers
                }else if(response.status=='error'){
                    this.ascent=false
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        getCollectionFails(){
            this.isLoading = true;
            collection_fails.postCollectionsFailsThemes(this.theme_ids).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    let ids=[];
                    for (const element of response.registers) {
                        console.log(element);
                        if (element.questions_ids!=''){
                            if (element.questions_ids.includes(',')){
                                let split=element.questions_ids.split(",")
                                for (const spl of split){
                                    ids.push(spl);
                                }
                            }else{
                                ids.push(element.questions_ids)
                            }
                        }
                    }
                    if (ids.length>0){
                        this.getQuestionIds(ids)
                    }
                }else if(response.status=='error'){
                    this.ascent=false
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
            this.isLoading = false;
        },
        getQuestionIds(ids){
            questions.getQuestionsIds(ids).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    // response.registers.forEach((element) => {
                    //     element.alternatives=JSON.parse(element.alternatives)
                    // });
                    let questions=response.registers;
                    this.practice.questions=questions
                    this.practice.totalQuestions=questions.length
                    this.practice.totalUnanswered=questions.length
                    this.cronometro()
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.$router.push({ name: 'PracticeTheme'});
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },

        getQuestionsThemeId(theme_id,quantity=''){
            this.isLoading = true;
            questions.getQuestionsThemeId(theme_id,quantity).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    response.registers.forEach((element) => {
                        // console.log(element)
                        element.alternatives=JSON.parse(element.alternatives)
                        element.question=element.question.replaceAll('"', '&quot;')
                        this.practice.questions.push(element)
                    });
                    let questions=response.registers.length
                    this.practice.totalQuestions+=questions
                    this.practice.totalUnanswered+=questions
                    this.practice.title=this.themes[this.practice.questions[0].theme_id]
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.$router.push({ name: 'PracticeTheme'});
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
            this.isLoading = false;
        },
        search(){
            if (this.searchQuestion>0 && this.searchQuestion<=this.practice.questions.length){
                if (this.searchQuestion!=''){
                    this.practice.view=parseInt(this.searchQuestion-1)
                }
            }
        },
        next(){
            let next=this.practice.view+1
            if(next>(this.practice.totalQuestions-1)){return false}
            this.practice.view=next
            this.practice.title=this.themes[this.practice.questions[next].theme_id]
        },
        prev(){
            let next=this.practice.view-1
            if(next<0){return false}
            this.practice.view=next
            this.practice.title=this.themes[this.practice.questions[next].theme_id]
        },
        cronometro(){
            setInterval(() => {
                this.tiempo.segundo++
                if(this.tiempo.segundo >= 60){
                    this.tiempo.segundo = 0;
                    this.tiempo.minuto++;
                }

                // Minutos
                if(this.tiempo.minuto >= 60){
                    this.tiempo.minuto = 0;
                    this.tiempo.hora++;
                }
                let horas=this.tiempo.hora < 10 ? '0' + this.tiempo.hora : this.tiempo.hora
                let minutos=this.tiempo.minuto < 10 ? '0' + this.tiempo.minuto : this.tiempo.minuto
                let segundos=this.tiempo.segundo < 10 ? '0' + this.tiempo.segundo : this.tiempo.segundo

                this.duration=horas+":"+minutos+":"+segundos
            }, 1000);
        },
        validate(event){
            let questionId=event.target.parentElement.getAttribute('questionId')
            let questionAnswer=document.getElementById('question'+questionId).value;
            if(questionAnswer==''){
                let answerElemente=event.target.parentElement
                let answer=event.target.parentElement.getAttribute('answer')

                if(answer==1){
                    answerElemente.parentElement.classList.add("success");
                    this.practice.totalUnanswered--
                    this.practice.totalAnswer++
                    $("#question"+questionId).val(1)
                }else{
                    answerElemente.parentElement.classList.add("error");
                    $(".question"+questionId+" .alternative").each(function() {
                        if($(this).find(".labelAlter").attr("answer")==1){
                            $(this).addClass("success")
                        }
                    });
                    this.practice.totalUnanswered--
                    this.practice.totalFails++
                    $("#question"+questionId).val(0)
                }

                this.practice.porcentage=(this.practice.totalAnswer/this.practice.totalQuestions)*100;
                this.practice.approved=this.practice.porcentage>=this.userData.minimun_score?1:0;
                console.log(this.practice)
            }
        },
        finishPractice(){
            this.popupClose()
            this.isLoading=true;
            let json={
                "customer_id":this.userData.id,
                "type":this.practice.type,
                "theme_q_question":this.practice.theme_q_question,
                "q_question":this.practice.totalQuestions,
                "q_answered":this.practice.totalAnswer+this.practice.totalFails,
                "q_answer":this.practice.totalAnswer,
                "q_fail":this.practice.totalFails,
                "porcentage":Math.ceil(this.practice.porcentage),
                "approved":this.practice.approved,
                "duration":this.duration
            };
            practices.storePractice(json).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    let practice_id=response.practice_id
                    //console.log(practice_id)
                    let questions=[]
                    let answereds=[]
                    let answers=[]
                    let fails=[]
                    //console.log(this.themes)
                    let themes=this.themes
                    let routerr=this.$router;
                    themes.forEach(function(index,theme){
                        questions[theme]=[]
                        answereds[theme]=[]
                        answers[theme]=[]
                        fails[theme]=[]
                    })
                    let q_questions=this.practice.questions.length
                    let aument=0;
                    this.practice.questions.forEach(function(question){
                        questions[question.theme_id].push({'id':question.id,'value':question.question});
                        question.alternatives.forEach(function(alternative){
                            if (alternative.answer==1){
                                answers[question.theme_id].push({'id':question.id,'value':alternative.value});
                            }
                        })
                        let answer=document.getElementById("question"+question.id);
                        if (answer.value==1){
                            answereds[question.theme_id].push({'id':question.id,'value':$(".question"+question.id+" .success .labelAlter").attr("text")})
                            fails[question.theme_id].push({'id':question.id,'value':'null'})
                        }else if (answer.value==="0"){
                            answereds[question.theme_id].push({'id':question.id,'value':'null'})
                            fails[question.theme_id].push({'id':question.id,'value':$(".question"+question.id+" .error .labelAlter").attr("text")})
                        }else{
                            answereds[question.theme_id].push({'id':question.id,'value':'null'})
                            fails[question.theme_id].push({'id':question.id,'value':'null'})
                        }
                        aument++
                        if (q_questions==aument){
                            console.log(themes)
                            themes.forEach(function(title,theme_id){
                                if (questions[theme_id].length>0){
                                    let q_answer=0
                                    answereds[theme_id].forEach(function(answer){
                                        if (answer.value!="null"){
                                            q_answer++
                                        }
                                    })

                                    let porcentage=(q_answer/questions[theme_id].length)*100
                                    console.log(porcentage)
                                    let jsonDetail={
                                        "practice_id":practice_id,
                                        "theme_id":theme_id,
                                        "theme_title":title,
                                        "porcentage":Math.ceil(porcentage),
                                        "questions":JSON.stringify(questions[theme_id]),
                                        "answereds":JSON.stringify(answereds[theme_id]),
                                        "answers":JSON.stringify(answers[theme_id]),
                                        "fails":JSON.stringify(fails[theme_id]),
                                    }
                                    console.log(jsonDetail)
                                    practice_details.storePracticeDetail(jsonDetail).then(response=>{
                                        if (response.status=='success'){
                                            console.log(response)
                                        }else if(response.status=='error'){
                                            console.log(response.message)
                                        }else if (response.status=='token'){
                                            alert("token expirado")
                                            this.$router.push({ name: 'Logout'});
                                        }
                                    }).catch(error=>{
                                        console.error(error);
                                    })
                                }
                                routerr.push({ name: 'PracticeResult',params:{id:practice_id}});
                            })
                        }
                    })
                }else if(response.status=='error'){
                    if (response.message=='no record'){
                        this.$router.push({ name: 'PracticeTheme'});
                    }else{
                        this.error=true;
                        this.error_msg=response.message;
                    }
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        openPopupFinalizar(){
            $(".popup").fadeIn()
        },
        popupClose(){
          $(".popup").fadeOut()
        }
    },
    mounted() {
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.userData=JSON.parse(localStorage.user)
            this.group_id=this.userData.group_id
            this.headers={
                token: localStorage.token,
                application: 'web'
            }
            this.practice.type=this.$route.query.type
            this.practice.quantityExam=this.$route.query.quantityExam

            this.loadPractice();
        }
    }
}
</script>
<style>
    .practice .question{
        display: none;
    }
    .practice .question.active{
        display: block;
    }
    .practice .alternative label{
        display: flex;
        align-items: center;
        text-align: left;
        width: 100%;
        padding-bottom: 5px;
        font-size: 12px;
    }
    .practice span.checkmark:before {
        width: 25px;
        height: 25px;
        display: block;
        content: "";
    }
    .practice .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 2px;
        height: 9px;
        border: solid #18C0C9;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .practice .checkmark:after {
        width: 100%;
        height: 100%;
        border: none;
        background-color: #299736;
        top: 0;
        left: 0;
        transform: inherit;
    }
    .practice .checkmark{
        position: relative;
        display: inline-block;
        background-color: #fff;
        border: 1px solid #707070;
        border-radius: 3px;
        /*margin-right: 10px;*/
        cursor: pointer;
        width: 25px !important;
        height: 25px !important;
        margin-right: 5px;
    }
    .practice .success .checkmark:after {
        display: block;
        background-color: #299736;
    }
    .practice .error .checkmark:after {
        display: block;
        background-color: #ED1C24;
    }

    .popup{
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgb(0 0 0 / 90%);
        top: 0;
        left: 0;
        z-index: 99;
    }
    .popupContent{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .popupFinalizar{
        background-color: #fff;
        padding: 40px 20px;
        border-radius: 8px;
        max-width: 600px;
        position: relative;
    }
    .popup-title{
        font-size: 35px;
    }
    .popup-close{
        position: absolute;
        right: 10px;
        top: 10px;
    }


    .theme_title{
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .question_title{
        font-size: 22px;
        text-transform: uppercase;
    }
    .question_alternative{
        font-size: 18px;
        text-transform: uppercase;
    }
    .question_code{
        font-size: 16px;
    }
    @media (max-width: 767px){
        .theme_title{
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
        }
        .question_title{
            font-size: 16px;
        }
        .question_alternative{
            font-size: 14px;
        }
        .question_code{
            font-size: 14px;
        }
    }

</style>
