<template>
    <div>
        <button @click="startListening">Comenzar a escuchar</button>
        <button @click="stopListening">Detener</button>
        <p>Texto transcrito: {{ transcription }}</p>
    </div>
</template>

<script>
import VueSpeechRecognition from 'vue-speech-recognition'

export default {
    name: 'MyComponent',
    mixins: [VueSpeechRecognition.mixin],
    data() {
        return {
            transcription: '',
            listening: false // nueva propiedad booleana
        }
    },
    methods: {
        startListening() {
            if (this.$speechRecognition.browserSupportsSpeechRecognition() && !this.listening) {
                this.listening = true // establece la propiedad listening en true
                this.$speechRecognition.startListening()
            } else {
                console.log('Tu navegador no soporta el reconocimiento de voz o ya está escuchando')
            }
        },
        stopListening() {
            if (this.listening) {
                this.listening = false // establece la propiedad listening en false
                this.$speechRecognition.stopListening()
            }
        }
    },
    mounted() {
        this.$speechRecognition.onResult = (transcription) => {
            this.transcription = transcription
        }
    }
}
</script>
