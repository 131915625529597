<template>
    <div class="home">
        <HelloWorld msg="Welcome to Your Vue.js App"/>
    </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
    name: 'LogoutView',
    components: {
        HelloWorld
    },
    mounted(){
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.clear();
        this.$router.push({ name: 'Login'});
    }

}
</script>
