<template>
    <LayoutIntranet>
        <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>
        <div class="row">
            <div class="col-lg-4">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex flex-column align-items-center text-center">
                            <img src="@/assets/images/icon-male.png" alt="Admin" class="rounded-circle p-1 bg-primary" width="110">
                            <div class="mt-3">
                                <h4>{{customerData.names}} {{customerData.lastname}}</h4>
                                <p class="text-secondary mb-1">{{customerData.grade_name}}</p>
                            </div>
                        </div>
                        <hr class="my-4">
                        <h5>Suscripción</h5>
                        <ul class="list-group list-group-flush">
                            <li v-if="customerData.suscription=='A'" class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                <h6 class="mb-0">Periodo</h6>
                                <span class="text-secondary">Del {{customerData.period}} al {{customerData.period_finish}}</span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                <h6 class="mb-0">Suscripción</h6>
                                <span class="text-secondary">
                                    <span v-if="customerData.suscription=='A'">Activo</span>
                                    <span v-else>Inactivo</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="card">
                    <div class="card-body">

                        <div class="row m-3">
                                <div v-if="alert.success" class="alert alert-success border-0 bg-success alert-dismissible fade show">
									<div class="text-white">{{alert.message}}</div>
									<!-- <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> -->
								</div>
                                <div v-if="alert.error" class="alert alert-danger border-0 bg-danger alert-dismissible fade show">
									<div class="text-white">{{alert.message}}</div>
									<!-- <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> -->
								</div>
                            </div>

                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Nickname</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                                <input type="text" class="form-control" v-model="form.nickname">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Nombres</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                                <span class="text-left form-control">{{customerData.names}}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Apellido paterno</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                                <span class="text-left form-control">{{customerData.lastname}}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Apellido materno</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                                <span class="text-left form-control">{{customerData.mother_lastname}}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Grupo</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                                <span class="text-left form-control">{{customerData.grade_name}}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Grado</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                                <span class="text-left form-control">{{customerData.group_name}}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Email</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                                <span class="text-left form-control">{{customerData.email}}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Celular</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                                <span class="text-left form-control">{{customerData.phone}}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-sm-3">
                                <h6 class="mb-0">Generó</h6>
                            </div>
                            <div class="col-sm-9 text-secondary">
                                <span class="text-left form-control">{{customerData.gender}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col-sm-9 text-secondary">
                                <button  class="btn btn-primary px-4" @click="updateNickname">Actualizar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import breadcrumbComponent from "@/components/Breadcrumb"
import customers from "@/store/customers";


export default {
    name: 'PerfilView',
    data() {
        return {
            breadcrumb: {
                title: 'Perfil',
                navbar:[
                    {
                        title:'Perfil',
                        link:''
                    }
                ]
            },
            customerData:[],
            userData:null,
            headers:{},
            user:[],
            form:{
                nickname:''
            },
            alert:{
                success:false,
                error:false,
                message:''
            }
        }
    },
    components: {
        breadcrumbComponent,
        LayoutIntranet
    },
    methods:{
        customer(){
            this.isLoading = true;
            customers.getShow().then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    this.customerData=response.register
                    let period=this.customerData.period.split('-')
                    this.customerData.period= period[2]+'/'+period[1]+'/'+period[0]
                    let period_finish=this.customerData.period_finish.split('-')
                    this.customerData.period_finish= period_finish[2]+'/'+period_finish[1]+'/'+period_finish[0]
                    this.form.nickname=this.customerData.nickname
                }else if(response.status=='error'){
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })

            this.isLoading = false;
        },
        updateNickname(){
            this.isLoading = true;
            console.log(this.form)
            customers.postNickname(this.form).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    this.alert.success=true
                    this.alert.message='Nickname actualizado correctamente';
                    this.alert.error=false
                }else if(response.status=='error'){
                    this.alert.error=false
                    this.alert.message=response.message
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
    },
    mounted() {
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.userData=JSON.parse(localStorage.user)
            this.headers={
                token: localStorage.token,
                application: 'web'
            }
            this.customer()
        }
    }
}
</script>
