import axios from 'axios';

export default {
    getQuestionsThemeId(theme_id,quantity=''){
        let url=process.env.VUE_APP_API_URL+'questions/theme_id/'+theme_id;
        if(quantity!=''){
            url+="/"+quantity
        }
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.get(url,{headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    },
    getQuestionsZone(params) {
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.get(process.env.VUE_APP_API_URL+'questions/zone-study',{headers,params:params}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    },
    getQuestionsIds(ids){
        let array={
            ids:ids
        }
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.post(process.env.VUE_APP_API_URL + 'questions/ids', array,{headers}).then(response => {
            console.log(response.data)
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    },
}
