<template>
  <LayoutIntranet :isLoading="isLoading">
    <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>
    <div class="row justify-content-center">
        <div class="col-12 col-md-11">
            <div class="card border border-2 border-warning bg-black text-white p-4">
                <p>Realiza un práctica con los temas y cantidad de preguntas de tu elección:</p>
                <div class="d-flex justify-content-center" style="font-weight: 900;">
                    <span>Temas: {{themes.length}}</span>
                    <span class="border-1 border-white mx-1">|</span>
                    <span>Preguntas: {{totalQuestions}}</span>
                </div>
            </div>

            <div class="d-block mb-5" v-if="predeterminadas">
                <p><b>Preguntas predeterminadas</b></p>
                <router-link class="d-inline-block btn bg-dark text-white mx-2 mb-2" style="border-radius: 0;"
                    :to="{ name: 'PracticeInit', query: {type: 'quantity', quantityExam:'10'} }">10 preguntas
                </router-link>
                <router-link class="d-inline-block btn bg-dark text-white mx-2 mb-2" style="border-radius: 0;"
                    :to="{ name: 'PracticeInit', query: {type: 'quantity', quantityExam:'20'} }">20 preguntas
                </router-link>
                <router-link class="d-inline-block btn bg-dark text-white mx-2 mb-2" style="border-radius: 0;"
                    :to="{ name: 'PracticeInit', query: {type: 'quantity', quantityExam:'30'} }">30 preguntas
                </router-link>
                <router-link class="d-inline-block btn bg-dark text-white mx-2 mb-2" style="border-radius: 0;"
                    :to="{ name: 'PracticeInit', query: {type: 'quantity', quantityExam:'50'} }">50 preguntas
                </router-link>
                <router-link class="d-inline-block btn bg-dark text-white mx-2 mb-2" style="border-radius: 0;"
                    :to="{ name: 'PracticeInit', query: {type: 'quantity', quantityExam:'all'} }">Todas las preguntas
                </router-link>
            </div>

            <div class="card">
                <div class="card-header">
                    <div class="form-group">
                        <label class="d-block text-left mb-1">Seleccionar grupo:</label>
                        <select id="questionGroup" class="form-select" v-model="questionGroup">
                            <option value="all">({{totalQuestions}}) TODAS LAS PREGUNTAS</option>
                            <option value="fails">({{totalFails}}) LAS QUE HE FALLADO</option>
                        </select>
                    </div>

                </div>
                <div v-show="questionGroup=='all'" class="card-body">
                    <div class="row">
                        <div v-for="theme in themes" :key="theme" class="col-12 col-md-6 col-xl-4 mb-3">
                            <div class="boxTheme border border-2 border-warning p-4 h-100">
                                <div class="d-flex mb-3">
                                    <a class="btn btn-dark" v-on:click="qMin" style="border-radius: 0px;">-</a>
                                    <input class="form-control text-center q_question" type="number" :value="group_theme_id[theme.id].q_question_theme" style="border-radius: 0px;" :theme_id="theme.id" :min="0" :max="theme.count_questions" v-on:change="validateq">
                                    <a class="btn btn-dark" v-on:click="qMax" style="border-radius: 0px;">+</a>
                                </div>
                                <label class="text-uppercase">{{theme.title}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="mt-3">
                        <button v-show="questionGroup=='all'" class="btn btn-warning text-white px-5 mb-2" @click="questionThemes">GENERAR PRÁCTICA</button>
                        <router-link v-show="questionGroup=='fails'" class="btn btn-warning text-white px-5 mb-2"
                            :to="{ name: 'PracticeInit', query: {type: 'fails'} }">GENERAR PRÁCTICA
                        </router-link>
                        <br>
                        <router-link class="px-5 mt-2 text-uppercase"
                            :to="{ name: 'PracticeHistory' }">Ver historial
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import breadcrumbComponent from "@/components/Breadcrumb"
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import group_themes from "@/store/group_themes";
import themes from "@/store/themes";
import collection_fails from "@/store/collection_fails";

export default {
    name: 'PracticeIndex',
    data() {
        return {
            breadcrumb: {
                title: 'Generador de práctica',
                navbar:[
                  {
                    title: 'Práctica',
                    link:''
                  }
                ]
            },
            predeterminadas:false,
            questionGroup:'all',
            urlHost:'',
            theme_ids:[],
            group_theme_id:[],
            themes:[],
            totalQuestions:0,
            totalFails:0,
            isLoading:true
        }
    },
    components: {
        LayoutIntranet,
        breadcrumbComponent,
    },
    methods:{
        getGroupThemes(){
            this.isLoading = true;
            group_themes.getGroupThemes().then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    response.registers.forEach((register) => {
                        this.theme_ids.push(register.theme_id)
                        this.group_theme_id[register.theme_id]=register
                    });
                    this.getThemes(this.theme_ids)
                }else if(response.status=='error'){
                    this.ascent=false
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        getThemes(theme_ids){
            this.isLoading = true;
            themes.postThemes(theme_ids).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    this.themes=response.registers
                    //console.log(this.themes)
                    this.themes.forEach((register) => {
                        this.totalQuestions=this.totalQuestions+register.count_questions
                    });
                    this.getCollectionFails()
                }else if(response.status=='error'){
                    this.ascent=false
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
            this.isLoading = false;
        },
        getCollectionFails(){
            this.isLoading = true;
            collection_fails.postCollectionsFailsThemes(this.theme_ids).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    response.registers.forEach((register) => {
                        let split=register.questions_ids.split(",")
                        this.totalFails=this.totalFails+split.length
                    });
                }else if(response.status=='error'){
                    console.log(response.message)
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
            this.isLoading = false;
        },
        qMin(event){
            let input=event.target.nextSibling
            let inputValue=input.value
            let minim=parseInt(inputValue)-1
            if(minim<0){
                return
            }
            input.value=minim
        },
        qMax(event){
            let input=event.target.previousSibling
            let inputValue=input.value
            let inputMax=input.max
            let aument=parseInt(inputValue)+1
            if(aument>inputMax){
                return
            }
            input.value=aument
        },
        validateq(event){
            let qmax=event.target.max
            let inputValue=event.target.value
            if(inputValue>qmax){ event.target.value=qmax }
            if(inputValue<0){ event.target.value=0 }
        },
        questionThemes(){
            let q_questions=[];
            document.querySelectorAll('.q_question').forEach(function(event) {
                q_questions.push({
                    'theme_id':event.getAttribute("theme_id"),
                    'q_question':event.value
                });
            });
            this.$router.push({ name: 'PracticeInit', query: {type: 'all', q_questions: JSON.stringify(q_questions) } });
        }
    },
    mounted() {
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.urlHost=process.env.VUE_APP_API_URL
            this.userData=JSON.parse(localStorage.user)
            this.headers={
                token: localStorage.token,
                application: 'web',
                user_id: this.userData.id
            }
            this.getGroupThemes()
            if (this.userData.suscription=='A'){ this.predeterminadas=true}
        }
    }
}
</script>
<style></style>
