<template>
    <LayoutIntranet :isLoading="isLoading">
        <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>
        <div class="simulationIndex">
            <div class="row justify-content-center">
                <div class="col-12 col-md-6">
                    <h3 class="mb-5">Generador de simulacros</h3>
                    <div class="card border border-2 border-warning bg-black text-white p-4">
                        <p>Realiza tu simulacro de examen, elige entre 100 o 300 preguntas. Además puedes seleccionar si usarás la versión PNP.</p>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="card col-md-4">
                        <div class="card-body">
                            <div class="mb-3">
                                <div class="check__group">
                                    <label for="pregunta_valor_1">
                                        <input v-model="cantidad" class="d-none" type="radio" id="pregunta_valor_1" value="100">
                                        <span class="checkmark"></span>
                                        <span>Simulacro de 100</span>
                                    </label>
                                </div>
                                <div class="check__group">
                                    <label for="pregunta_valor_2">
                                        <input v-model="cantidad" class="d-none" type="radio" id="pregunta_valor_2" value="300">
                                        <span class="checkmark"></span>
                                        <span>Simulacro de 300</span>
                                    </label>
                                </div>
                            </div>
                            <div class="mb-3">
                                <select v-model="version" class="form-control">
                                    <option value=" " disabled="">Versión de simulacro</option>
                                    <option value="aguilas" selected="selected">Generar Versión águila Seis</option>
                                    <option value="pnp">Generar Versión PNP</option>
                                    <option value="siecopol">Generar Versión SIECOPOL</option>
                                </select>
                            </div>
                            <div class="mb-2">
                                <button class="btn btn-warning" @click="generate()">GENERAR SIMULACRO</button>
                            </div>
                            <div>
                                <router-link :to="{ name: 'SimulationHistory'}">
                                    VER HISTORIAL
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import breadcrumbComponent from "@/components/Breadcrumb"
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import permissionHelper from "@/helpers/validatePermission.js";

export default {
    name: 'SimulationIndex',
    data() {
        return {
            breadcrumb: {
                title: 'Simulacro',
                navbar:[
                    {
                        title: 'Simulacro',
                        link:''
                    }
                ]
            },
            cantidad:100,
            version:'aguilas'
        }
    },
    components: {
        LayoutIntranet,
        breadcrumbComponent,
    },
    methods:{
        generate(){
            let json={
                cantidad: this.cantidad,
                version: this.version
            }
            this.$router.push({ name: 'SimulationInitial',query: json});
        }
    },
    mounted() {
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.urlHost=process.env.VUE_APP_API_URL
            this.userData=JSON.parse(localStorage.user)
            this.headers={
                token: localStorage.token,
                application: 'web',
                user_id: this.userData.id
            }
            if (!permissionHelper.valiPermission('autoevaluacion',this.userData.permissions) || this.userData.suscription=='I'){
                this.$router.push({ name: 'Dashboard'});
            }
        }
    }
}
</script>
<style>
    .simulationIndex .check__group label {
        display: flex;
        align-items: center;
    }
    .simulationIndex .check__group .checkmark {
        position: relative;
        width: 16px;
        height: 17px;
        display: block;
        background-color: #fff;
        border: 1px solid #707070;
        border-radius: 3px;
        margin-right: 10px;
    }
    .simulationIndex input:checked ~ .checkmark:after {
        display: block;
    }
    .simulationIndex .check__group .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 2px;
        height: 9px;
        border: solid #18C0C9;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
</style>
