<template>
    <LayoutIntranet :isLoading="isLoading">
        <breadcrumb-component :breadcrumb="breadcrumb"></breadcrumb-component>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div>
                            <h1><strong>Zona de estudio</strong></h1>
                            <p>Aqui podras escuchar tus preguntas</p>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group text-left">
                                    <label>Temas</label>
                                    <select class="form-control" @change="changeTheme($event)" v-model="theme_id">
                                        <option value="">Seleccionar tema</option>
                                        <option v-for="theme in themes" :key="theme" :value="theme.id" :countQuestions="theme.count_questions">({{theme.count_questions}}) {{theme.title}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group text-left">
                                    <label>Desde</label>
                                    <input type="text" v-model="init" class="form-control" placeholder="1">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group text-left">
                                    <label>Hasta</label>
                                    <input type="text" v-model="finish" class="form-control" placeholder="Hasta">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <button class="btn btn-warning w-100 mb-1" @click="getQuestions">Buscar</button>
                                <button class="btn btn-dark w-100" @click="questionClear">Limpiar</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-12 col-md-6">
                                <div class="d-flex justify-content-center">
                                    <button class="btn btn-dark mx-1" @click="play">
                                        <i class="fa fa-play text-warning  font-30"></i>
                                        <p class="p-0 m-0 font-14">Iniciar</p>
                                    </button>
                                    <button class="btn btn-dark mx-1" @click="pausar">
                                        <i class="fa fa-pause text-warning  font-30"></i>
                                        <p class="p-0 m-0 font-14">Pausar</p>
                                    </button>
                                    <button class="btn btn-dark mx-1" @click="detener">
                                        <i class="fa fa-stop text-warning  font-30"></i>
                                        <p class="p-0 m-0 font-14">Detener</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div v-for="question,index in questions" :key="index" :class="'question_item question_item_'+index">
                                    <hr>
                                    <p class="question text-left" v-html="(index+1)+'. '+question.question"></p>
                                    <p class="answer text-left">RESPUESTA: {{question.answer}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </LayoutIntranet>
</template>

<script>
// @ is an alias to /src
import LayoutIntranet from '@/layouts/LayoutIntranet.vue'
import breadcrumbComponent from "@/components/Breadcrumb"
import $ from "jquery";
import group_themes from "@/store/group_themes";
import themes from "@/store/themes";
import questions from "@/store/questions";
import permissionHelper from "@/helpers/validatePermission";
export default {
    name: 'ZoneStudy',
    components: {
        LayoutIntranet,
        breadcrumbComponent,
    },
    data() {
        return {
            breadcrumb: {
                title: 'Zona de estudio',
                navbar: [
                    {
                        title: 'Dashboard',
                        link: ''
                    },
                    {
                        title: 'Zona de estudio',
                        link: ''
                    }
                ]
            },
            isLoading: false,
            userData:{},
            group_theme_ids:[],
            questions:[],
            themes:[],
            theme_id:'',
            totalQuestions:0,
            init:1,
            finish:'',


            position:0,
            stop:false,
            pause:false,
            question:false,
            answer:false,

            text_to_speech:'',
            synth :'',
            // text_to_speech:new SpeechSynthesisUtterance(),
            // synth : window.speechSynthesis,
            // text_to_speech.voice = synth.getVoices()[0];
            //
            // var position=$("#position").val()
            // var quantity=$("#quantity").val();

        }
    },
    methods:{
        getGroupThemes(){
            this.isLoading = true;
            group_themes.getGroupThemes().then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    response.registers.forEach((register) => {
                        this.group_theme_ids.push(register.theme_id)
                    });
                    this.getThemes(this.group_theme_ids)
                }else if(response.status=='error'){
                    this.ascent=false
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        getThemes(theme_ids){
            this.isLoading = true;
            themes.postThemes(theme_ids).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    this.themes=response.registers
                    //console.log(this.themes)
                    this.themes.forEach((register) => {
                        this.totalQuestions=this.totalQuestions+register.count_questions
                    });
                }else if(response.status=='error'){
                    this.ascent=false
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
            this.isLoading = false;
        },
        getQuestions(){
            this.isLoading = true;
            if (this.theme_id==''){
                alert("debe seleccionar un tema")
                this.isLoading = false;
                return
            }
            if (this.finish==''){
                this.finish=this.totalQuestions;
            }
            let params={
                'theme_id':this.theme_id,
                'offset':this.init-1,
                'finish':this.finish
            }
            questions.getQuestionsZone(params).then(response=>{
                this.loading=true;
                if (response.status=='success'){
                    response.registers.forEach((element) => {
                        let alternatives=JSON.parse(element.alternatives)
                        alternatives.forEach((alternative) => {
                            if (alternative.answer==1){
                                element.answer=alternative.value;
                            }
                        });
                    });
                    this.questions=response.registers;
                    console.log(this.questions)
                    this.isLoading=false;
                }else if(response.status=='error'){
                    this.ascent=false
                    console.log('error')
                }else if (response.status=='token'){
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        questionClear(){
            this.init=1
            this.finish=''
            this.theme_id=''
            this.questions=[]
            this.detener()
        },
        changeTheme(event){
            if (event.target.value!=''){
                let options = event.target.options
                if (options.selectedIndex > -1) {
                    var countQuestions = options[options.selectedIndex].getAttribute('countQuestions');
                }
                this.totalQuestions=countQuestions;
            }
        },
        leerPregunta(){
            if (this.stop==true){ return; }
            let position=this.position;
            this.text_to_speech.onboundary = function(e) {
                var message = $('.question_item_'+position+' .question').text()
                var b_text = String(message).substring(0, e.charIndex + e.charLength)
                var marked = $('<span>')
                marked.text(b_text)
                $('.question_item_'+position+' .question').html('')
                $('.question_item_'+position+' .question').append(marked)
                $('.question_item_'+position+' .question').append(message.replace(b_text, ""))
            }
            this.text_to_speech.text = $('.question_item_'+this.position+' .question').text();
            this.synth.speak(this.text_to_speech)
            this.text_to_speech.onend = () => {
                this.leerRespuesta();
            }
        },
        leerRespuesta(){
            if (this.stop==true){ return; }
            let position=this.position;
            this.text_to_speech.onboundary = function(e) {
                var message = $('.question_item_'+position+' .answer').text()
                var b_text = String(message).substring(0, e.charIndex + e.charLength)
                var marked = $('<span>')
                marked.text(b_text)
                $('.question_item_'+position+' .answer').html('')
                $('.question_item_'+position+' .answer').append(marked)
                $('.question_item_'+position+' .answer').append(message.replace(b_text, ""))
            }
            this.text_to_speech.text = $('.question_item_'+this.position+' .answer').text();
            this.synth.speak(this.text_to_speech)
            this.text_to_speech.onend = () => {
                if (position==this.totalQuestions){
                    this.synth.cancel();
                }else{
                    this.position=position+1
                    this.leerPregunta();
                }

            }
        },
        play(){
            this.stop=false;
            if (this.pause==true) {
                this.synth.resume();
                this.pause=false;
            }else{
                this.leerPregunta();
            }
        },
        pausar(){
            this.synth.pause();
            this.pause=true;
        },
        detener(){
            this.stop=true;
            this.pause=false;
            this.position=0
            this.synth.cancel();
            $(".question" ).each(function( ) {
                if ($(this).find("span").length) {
                    let text = $(this).find("span").text();
                    $(this).find("span").remove();
                    $(this).prepend(text);
                }
            });
            $(".answer" ).each(function( ) {
                if ($(this).find("span").length){
                    let text=$(this).find("span").text();
                    $(this).find("span").remove();
                    $(this).prepend(text);
                }

            });
        }
    },
    mounted() {
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.userData=JSON.parse(localStorage.user)
            this.headers={
                token: localStorage.token,
                application: 'web'
            }
            if (!permissionHelper.valiPermission('zona-de-estudio',this.userData.permissions) || this.userData.suscription=='I'){
                this.$router.push({ name: 'Dashboard'});
            }
            this.getGroupThemes()

            this.text_to_speech=new SpeechSynthesisUtterance();
            this.synth= window.speechSynthesis;
            this.text_to_speech.voice = this.synth.getVoices()[0];
            this.text_to_speech.rate=1;
            this.text_to_speech.lang="es-ES";

            this.synth.cancel();
        }
    }
}
</script>
<style>
    .question_item span{
        background-color: #fecc00;
    }
</style>
