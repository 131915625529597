<template>
    <div class="card" v-if="merits.length>0">
        <div class="card-header text-left bg-black">
            <h5 class="text-white" style="font-weight: bold">Cuadro de méritos</h5>
            <span class=" text-white">Simulacros en los que se han participado</span>
        </div>
        <div class="card-body p-0">
            <table class="table w-100 text-left">
                <tr style="background-color: #afafaf" v-for="merit in merits" :key="merit">
                    <td class="p-3">
                        <router-link :to="{ name: 'PracticeGlobalScore',params:{code:merit.code}}"  class="text-black" style="font-weight: bold;">
                           {{merit.title}}
                        </router-link>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>

import intranet_practice from "@/store/intranet_practice";
export default {
    name: "BoxMerits",
    data() {
        return {
            userData:{},
            merits:[]
        }
    },
    components: {
    },
    mounted() {
        intranet_practice.getIntranetPracticesMerit().then(response=>{
            this.loading=true;
            if (response.status=='success'){
                this.loading = false;
                this.merits = response.registers
            }else if(response.status=='error'){
                console.log('error')
            }else if (response.status=='token'){
                alert("token expirado")
                this.$router.push({ name: 'Logout'});
            }
        }).catch(error=>{
            console.error(error);
        })
    }
};
</script>

<style>
nav{
    padding: 5px 30px;
}
</style>
