<template>
    <div class="wrapper" :style="{
      'background': 'url('+backgroundImage+')',
      'background-size':'cover'
    }">
        <div class="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
            <div class="container-fluid">
                <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                    <div class="col mx-auto">
                        <div class="mb-4 text-center">
                            <img src="@/assets/images/logo.png" width="300" alt="" />
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="border p-4 rounded">
                                    
                                    <div class="text-center">
                                        <h3 class=""><strong> Registro de usuario</strong></h3>
                                    </div>
                                    
                                    <div class="login-separater text-center mb-4">
                                        <hr/>
                                    </div>
                                    
                                    <div v-if="formErrors.length>0" class="alert alert-danger border-0 bg-danger alert-dismissible fade show">
                                      <div class="text-white text-left" v-for="error in formErrors" :key="error">{{error}}</div>
                                    </div>
                                    <div class="form-body">
                                        <form class="row g-3" v-on:submit.prevent="formSubmit">
                                            <div class="col-12 text-left">
                                                <label for="names" class="form-label">Nombres</label>
                                                <input v-model="form.names" type="text" class="form-control" id="names">
                                            </div>
                                            <div class="col-6 text-left">
                                                <label for="lastname" class="form-label">Apellido Paterno</label>
                                                <input v-model="form.lastname" type="text" class="form-control" id="lastname">
                                            </div>
                                            <div class="col-6 text-left">
                                                <label for="mother_lastname" class="form-label">Apellido Materno</label>
                                                <input v-model="form.mother_lastname" type="text" class="form-control" id="mother_lastname">
                                            </div>
                                            <div class="col-6 text-left">
                                                <label for="grade_id" class="form-label">Grados</label>
                                                <select v-model="form.grade_id" class="form-select" id="grade_id">
                                                    <option value="" selected>Elije tu grado</option>
                                                    <option v-for="grade in grades" :key="grade" :value="grade.id" > {{grade.name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-6 text-left">
                                                <label for="dni" class="form-label">Nro. DNI</label>
                                                <input v-model="form.dni" type="text" class="form-control" id="dni" @keypress="numbers">
                                            </div>
                                            <div class="col-6 text-left">
                                                <label for="phone" class="form-label">Celular</label>
                                                <input v-model="form.phone" type="text" class="form-control" id="phone">
                                            </div>
                                            <div class="col-6 text-left">
                                                <label for="gender" class="form-label">Genero</label>
                                                <select v-model="form.gender" class="form-select" id="gender">
                                                    <option value="">Seleccione su genero</option>
                                                    <option value="Masculino">Masculino</option>
                                                    <option value="Femenino">Femenino</option>
                                                </select>
                                            </div>
                                            <div class="col-12 text-left">
                                                <label for="email" class="form-label">Correo</label>
                                                <input v-model="form.email" type="email" class="form-control" id="email">
                                            </div>
                                            <div class="col-12 text-left">
                                                <label for="inputChoosePassword" class="form-label">Contraseña</label>
                                                <div class="input-group" id="show_hide_password">
                                                    <input v-model="form.password" type="password" class="form-control border-end-0" id="inputChoosePassword"> <a href="javascript:;" class="input-group-text bg-transparent"><i class='bx bx-hide'></i></a>
                                                </div>
                                            </div>
                                            <div class="col-md-12 text-center">
                                                <p><strong> hacer clic en REGISTRAR, estás aceptando los <a href="https://m.aguilaseis.com/politicaprivacidad" class="text-warning">términos y condiciones</a></strong></p>
                                            </div>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col">
                                                        <button type="submit" class="btn btn-warning w-100">
                                                            Registrar
                                                        </button>
                                                    </div>
                                                    <div class="col">
                                                        <router-link :to="{ name: 'Login'}" class="btn btn-outline-dark w-100">
                                                            Cancelar
                                                        </router-link>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end row-->
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import $ from 'jquery';

export default {
    name: 'LoginView',
    components: {
    },
    data:function () {
        return {
            backgroundImage: process.env.VUE_APP_API_UPLOADS+'60e447a381e35.jpg',
            grades:[],
            form:{
                names:'',
                lastname:'',
                mother_lastname:'',
                grade_id:'',
                dni:'',
                phone:'',
                gender:'',
                email:'',
                password:'',
            },
            formErrors:[],
            error:false,
            error_msg:'',
            headers:{},
        }
    },
    methods:{
        getGrades(){
            this.isLoading = true;
            axios.get(process.env.VUE_APP_API_URL+'grades')
            .then(data=>{
                if (data.data.status=='success'){
                    data.data.registers.forEach((register) => {
                        if(register.name!='--sin grado ---'){
                            this.grades.push(register)
                        }
                    });
                }else{
                    this.error=true;
                    this.error_msg=data.data.message;
                }
            })
        },
        formSubmit(e){
            e.preventDefault();
            this.formErrors=[];
            if(!this.form.names){ this.formErrors.push("El campo nombres es requerido") }
            if(!this.form.lastname){ this.formErrors.push("El campo Apellido paterno es requerido") }
            if(!this.form.mother_lastname){ this.formErrors.push("El campo Apellido materno es requerido") }
            if(!this.form.grade_id){ this.formErrors.push("Debe seleccionar un grado") }
            if(!this.form.dni){ this.formErrors.push("El campo DNI es requerido") }
            if(!this.form.phone){ this.formErrors.push("El campo celular es requerido") }
            if(!this.form.gender){ this.formErrors.push("Debe seleccionar un genero") }
            if(!this.form.email){ this.formErrors.push("El campo Correo es requerido") }
            if(!this.form.password){ this.formErrors.push("El campo Contraseña es requerido") }

            if(this.formErrors.length>0){
                return
            }

            let json=this.form;
            axios.post(process.env.VUE_APP_API_URL+'login', json)
              .then(data=>{
                if (data.data.status=='success'){
                  localStorage.token=data.data.token
                  localStorage.user=JSON.stringify({
                    id:data.data.register.id,
                    nickname:data.data.register.nickname,
                    grade_id:data.data.register.grade_id,
                    group_id:data.data.register.group_id,
                    suscription:data.data.register.suscription,
                  })
                  this.$router.push({ name: 'Dashboard'});
                }else{
                  this.error=true;
                  let message=data.data.message;
                  console.log(message)
                  if(Array.isArray(message.email)){
                    this.error_msg="El correo electronico es obligatorio";
                  }else{
                    this.error_msg=message;
                  }
                }
              })
        },
        numbers(event){
            var regex = new RegExp("^[0-9]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        },
        text(event){
            var regex = new RegExp("^[a-zA-Z ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        },
        textNumber(event){
            var regex = new RegExp("^[a-zA-Z0-9 ]+$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }
    },
    mounted() {
        this.getGrades()
        $("#show_hide_password a").on('click', function (event) {
            event.preventDefault();
            if ($('#show_hide_password input').attr("type") == "text") {
            $('#show_hide_password input').attr('type', 'password');
            $('#show_hide_password i').addClass("bx-hide");
            $('#show_hide_password i').removeClass("bx-show");
            } else if ($('#show_hide_password input').attr("type") == "password") {
            $('#show_hide_password input').attr('type', 'text');
            $('#show_hide_password i').removeClass("bx-hide");
            $('#show_hide_password i').addClass("bx-show");
            }
        });
    }
}
</script>
