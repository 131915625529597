import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//import VueSpeech from 'vue-speech'

//const app =createApp(App)
//app.use(VueSpeech)
//app.use(router)
//app.mount('#app')
createApp(App).use(router).mount('#app')
