import axios from 'axios';

export default {
    getFactorOptionsGrade(grade_id) {
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.get(process.env.VUE_APP_API_URL+'factor-options/grade/'+grade_id, {headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    }
}
