import axios from 'axios';

export default {
    getTheme(theme_id){
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.get(process.env.VUE_APP_API_URL+'themes/'+theme_id,{headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    },
    postThemes(theme_ids) {
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.post(process.env.VUE_APP_API_URL+'themes/theme_ids', {"theme_ids":theme_ids},{headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    }
}
