<template>
    <div class="alert alert-danger border-0 bg-danger alert-dismissible" v-if="view">
        <div class="text-white text-alertDashboard" style="font-weight: bold;">
            Faltan {{ countdown.days }} días {{ countdown.hours }} horas {{ countdown.minutes }} minutos {{ countdown.seconds }} segundos para el examen de conocimientos
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
</template>
<script>
import countdown_exams from "@/store/countdown_exams";

export default {
    name: "CountdownComponent",
    props:{
    },
    data() {
        return {
            view:false,
            userData:[],
            countdown_exam:[],
            countdown: {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
                targetDate: ""
            },
            timer: null, // variable para el temporizador
        }
    },
    methods: {
        getCoubtDonw(){
            countdown_exams.getCountdown().then(response=>{
                if (response.status=='success'){
                    this.view=false
                    let register=response.register;
                    if (register){
                        let grades=register.grade_ids.split(',')
                        for (var i = 0; i < grades.length; i+=1) {
                            if (grades[i]==this.userData.grade_id){
                                this.countdown_exam=register
                                this.countdown.targetDate=this.countdown_exam.datetime
                                this.startCountdown()
                            }
                        }
                    }
                }else if(response.status=='error'){
                    this.view=false
                    console.log('error')
                }else if (response.status=='token'){
                    this.view=false
                    alert("token expirado")
                    this.$router.push({ name: 'Logout'});
                }
            }).catch(error=>{
                console.error(error);
            })
        },
        startCountdown() {

            const targetTime = new Date(this.countdown.targetDate).getTime()
            this.timer = setInterval(() => {
                const now = new Date().getTime()
                const distance = targetTime - now
                this.countdown.days = Math.floor(distance / (1000 * 60 * 60 * 24))
                this.countdown.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
                this.countdown.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
                this.countdown.seconds = Math.floor((distance % (1000 * 60)) / 1000)

                if (distance <= 0) {
                    clearInterval(this.timer)
                    this.view=false
                    // hacer algo cuando se acabe el tiempo
                }else{
                    this.view=true
                }
            }, 1000) // actualiza el contador cada segundo
        }
    },
    mounted() {
        this.view=false
        if (localStorage.user==undefined || localStorage.token==undefined){
            this.$router.push({ name: 'Login'});
        }else {
            this.userData = JSON.parse(localStorage.user)
            this.getCoubtDonw()
        }
    }
};
</script>

<style>
nav{
    padding: 5px 30px;
}
</style>
