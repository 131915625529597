import axios from 'axios';

export default {
    getSimulationDetails(simulation_id) {
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.get(process.env.VUE_APP_API_URL+'simulation-details/practice/'+simulation_id,{headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    },
    storeSimulationDetail(json){
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.post(process.env.VUE_APP_API_URL + 'simulation-details/store', json,{headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    },
}
