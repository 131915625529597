<template>
    <ul class="metismenu" id="menu">
        <li>
            <router-link :to="{ name: 'Dashboard'}">
                <div class="parent-icon"><i class='bx bx-home-circle'></i></div>
                <div class="menu-title">Dashboard </div>
            </router-link>
        </li>
<!--        <li>-->
<!--            <router-link :to="{ name: 'Notices'}">-->
<!--                <div class="parent-icon"><i class='bx bx-home-circle'></i></div>-->
<!--                <div class="menu-title">Noticias </div>-->
<!--            </router-link>-->
<!--        </li>-->
        <li class="menu-label text-center">Exámenes</li>
        <li>
            <router-link :to="{ name: 'PracticeTheme'}">
                <div class="parent-icon"><i class='bx bx-spreadsheet'></i></div>
                <div class="menu-title">Practica por tema </div>
            </router-link>
        </li>
        <li v-if="permission.practice">
            <router-link :to="{ name: 'Practice'}">
                <div class="parent-icon"><i class='bx bx-spreadsheet'></i></div>
                <div class="menu-title">Generador de práctica </div>
            </router-link>
        </li>
        <li v-if="permission.simulation">
            <router-link :to="{name:'Simulation'}">
                <div class="parent-icon"><i class='bx bx-spreadsheet'></i></div>
                <div class="menu-title">Simulacros ilimitados </div>
            </router-link>
        </li>
        <li class="menu-label">Estudio</li>
        <li v-if="permission.temary">
            <router-link :to="{ name: 'Documents'}">
                <div class="parent-icon"><i class='lni lni-files'></i></div>
                <div class="menu-title">Documentos </div>
            </router-link>
        </li>
        <li v-if="permission.audios">
            <router-link :to="{ name: 'Audios'}">
                <div class="parent-icon"><i class='lni lni-music'></i></div>
                <div class="menu-title">Audios </div>
            </router-link>
        </li>
        <li v-if="permission.videos">
            <router-link :to="{ name: 'Videos'}">
                <div class="parent-icon"><i class='lni lni-play'></i></div>
                <div class="menu-title">Videos </div>
            </router-link>
        </li>
        <li v-if="permission.zonaStudy">
            <router-link :to="{ name: 'ZoneStudy'}">
                <div class="parent-icon"><i class='bx bx-play-circle'></i></div>
                <div class="menu-title">Zona de estudio </div>
            </router-link>
        </li>

<!--        <li class="menu-label">Cuenta</li>-->
<!--        <li  v-if="userData.ascent=='A'">-->
<!--            <router-link :to="{ name: 'AscentReport'}">-->
<!--                <div class="parent-icon"><i class='bx bx-play-circle'></i></div>-->
<!--                <div class="menu-title">Reporte de ascenso </div>-->
<!--            </router-link>-->
<!--        </li>-->
    </ul>
</template>
<script>
import MetisMenu from 'metismenujs';
import "metismenu/dist/metisMenu.min.css";
import permissionHelper from "@/helpers/validatePermission.js";

export default {
    name: "navigationComponent",
    data() {
        return {
            userData:{},
            permission:[],
        }
    },
    components: {
    },
    mounted() {
        if (localStorage.user===undefined || localStorage.token===undefined){
            this.$router.push({ name: 'Login'});
        }else{
            this.userData=JSON.parse(localStorage.user)
            this.permission.practice=permissionHelper.valiPermission('generador-practica',this.userData.permissions)
            this.permission.simulation=permissionHelper.valiPermission('autoevaluacion',this.userData.permissions)
            this.permission.temary=permissionHelper.valiPermission('temario',this.userData.permissions)
            this.permission.audios=permissionHelper.valiPermission('audios',this.userData.permissions)
            this.permission.videos=permissionHelper.valiPermission('videos',this.userData.permissions)
            this.permission.practiceGlobal=permissionHelper.valiPermission('practica-global',this.userData.permissions)
            this.permission.zonaStudy=permissionHelper.valiPermission('zona-de-estudio',this.userData.permissions)
            new MetisMenu('#menu');

        }
    },

};
</script>

<style>
</style>
