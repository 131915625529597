import axios from 'axios';

export default {

    // getIntranetPractices() {
    //     return axios.get(process.env.VUE_APP_API_URL+'/intranet-practices');
    // },
    getCountdown() {
        let userData=JSON.parse(localStorage.user);
        let headers={
            token: localStorage.token,
            application: 'web'
        }
        let reponse=axios.get(process.env.VUE_APP_API_URL+'countdown-exams/grade/'+userData.grade_id,{headers:headers}).then(response => {
            return response.data;
        }).catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    }
}
